import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row, Image, message, Flex } from 'antd';
import React from 'react';
import { useToggle } from 'react-use';
import { NJVButton, NJVInput } from '../../component/core-component';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { storeUserInfo } from '../../core/auth';
import { useUserInfo } from '../../App';
import { PasswordValidator } from '../../core/validator';

const LoginPage = () => {
  const [, setUserInfo] = useUserInfo();
  const [isLoading, toggleLoading] = useToggle(false);
  const logo = require('../../assets/zeimm_logo.png');
  const doLogin = (data) => {
    // navigate(CustomPath.admin);
    toggleLoading();
    ApiHandler({
      url: Api.login,
      method: HTTP_METHOD.POST,
      requestData: data,
      disableShowMessage: true,
    })
      .then((response) => {
        const data = response?.data;
        storeUserInfo(data);
        setUserInfo(data);
      })
      .catch((error) => {
        message.error(error.response?.data.message);
        console.log(error);
      })
      .finally(toggleLoading);
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={doLogin}
    >
      <Row gutter={[16, 16]} style={{ height: '100vh', display: 'flex', alignContent: 'center' }}>
        <Col span={8} />
        <Col span={8}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Image width={200} src={logo} preview={false} style={{ marginBottom: 20 }} />
            </Col>
            <Col span={24}>
              <Form.Item
                name="emailAddress"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <NJVInput size="large" bgcolor="#f1f1f1" placeholder="Email" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                ]}
              >
                <NJVInput bgcolor="#f1f1f1" type="password" placeholder="Password" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button style={{ width: '100%', height: 40 }} loading={isLoading} type="primary" htmlType="submit">
                  Log in
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        {/* <Col span={7} /> */}
      </Row>
    </Form>
  );
};

export default LoginPage;
