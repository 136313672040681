import { Breadcrumb, Button, Card, Checkbox, Col, Form, Row, Select, TimePicker } from 'antd';
import { NJVButton, NJVInput, NJVSelect, NJVTimePicker } from '../../component/core-component';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Theme from '../../component/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';
import dayjs from 'dayjs';
import { DayOfWeek, OpeningHoursStatus } from '../../core/constant';

function ChargingStationForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const openingHours = Object.keys(DayOfWeek).map((dayOfWeek) => ({
    dayOfWeek,
    openTime: '',
    closeTime: '',
    openingHoursStatus: OpeningHoursStatus.Open,
  }));

  useEffect(() => {
    if (state?.previousData) {
      formRef.current.setFieldsValue({
        ...state?.previousData,
        openingHours: state?.previousData?.openingHours?.map((oh) => ({
          ...oh,
          openTime: oh.openTime ? dayjs(oh.openTime) : '',
          closeTime: oh.closeTime ? dayjs(oh.closeTime) : '',
        })),
      });
    }
  }, []);

  const createChargingStation = (formData) => {
    setLoading(true);
    ApiHandler({
      url: Api.charging_station,
      method: HTTP_METHOD.POST,
      requestData: { ...formData, status: formData.active ? 'Active' : 'Inactive' },
    })
      .then(() => {
        backToHome();
      })
      .catch(() => setLoading(false));
  };
  const backToHome = () => navigate(CustomPath.charging_station);

  const chooseOnMap = () => {
    navigate(CustomPath.charging_station_map, {
      state: {
        data: {
          parent_route: CustomPath.charging_station_form,
          form_type: 'Create',
          ...formRef?.current?.getFieldsValue(),
          openingHours: formRef?.current?.getFieldValue('openingHours')?.map((oh) => {
            return {
              ...oh,
              openTime: oh.openTime ? oh.openTime.format('YYYY-MM-DD HH:mm') : '',
              closeTime: oh.closeTime ? oh.closeTime.format('YYYY-MM-DD HH:mm') : '',
            };
          }),
        },
      },
    });
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.charging_station}>Charging Station</Link>,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'Charging Station Form'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form
              layout="vertical"
              onFinish={createChargingStation}
              ref={formRef}
              initialValues={{
                active: true,
                openingHours,
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item name="name" label={'Name'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter Name'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="contactInformation"
                    label={'Contact Information'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput autoSize={{ minRows: 3, maxRows: 5 }} placeholder={'Enter Contact Information'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="address" label={'Address'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput
                      istextareainput={true}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder={'Enter Address'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="notes" label={'Notes'}>
                    <NJVInput
                      istextareainput={true}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder={'Enter Notes'}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="latitude" label={'Latitude'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter Latitiude'} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="longitude" label={'Longitude'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter Longitude'} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="map" label={'.'}>
                    <NJVButton
                      type="primary"
                      onClick={() => {
                        chooseOnMap();
                      }}
                    >
                      Choose on map
                    </NJVButton>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Card
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.05)',
                      backgroundColor: Theme.colors.card_bg_color,
                    }}
                    title="Opening Hours"
                  >
                    <Row gutter={[16, 16]}>
                      <Form.List name="openingHours">
                        {(fields) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <React.Fragment key={key}>
                                <Col span={5}>{DayOfWeek[openingHours[key]?.dayOfWeek]}</Col>
                                <Col span={5}>
                                  <Form.Item
                                    shouldUpdate={(prevValues, currentValues) => {
                                      return prevValues.openingHours !== currentValues.openingHours;
                                    }}
                                  >
                                    {({ getFieldValue }) => (
                                      <>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'openTime']}
                                          // rules={[{ required: true, message: '*(requried)' }]}
                                        >
                                          <NJVTimePicker
                                            format={'HH:mm'}
                                            disabled={
                                              getFieldValue(['openingHours', name, 'openingHoursStatus']) ===
                                              OpeningHoursStatus.Closed
                                            }
                                          />
                                        </Form.Item>
                                      </>
                                    )}
                                  </Form.Item>
                                </Col>
                                <Col span={5}>
                                  <Form.Item
                                    shouldUpdate={(prevValues, currentValues) => {
                                      return prevValues.openingHours !== currentValues.openingHours;
                                    }}
                                  >
                                    {({ getFieldValue }) => (
                                      <>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'closeTime']}
                                          // rules={[{ required: true, message: '*(requried)' }]}
                                        >
                                          <NJVTimePicker
                                            format={'HH:mm'}
                                            disabled={
                                              getFieldValue(['openingHours', name, 'openingHoursStatus']) ===
                                              OpeningHoursStatus.Closed
                                            }
                                          />
                                        </Form.Item>
                                      </>
                                    )}
                                  </Form.Item>
                                </Col>
                                <Col span={5}>
                                  <Form.Item name={[name, 'openingHoursStatus']}>
                                    <NJVSelect size="large">
                                      {Object.entries(OpeningHoursStatus).map(([key, value]) => (
                                        <Select.Option key={key} value={key}>
                                          {value}
                                        </Select.Option>
                                      ))}
                                    </NJVSelect>
                                  </Form.Item>
                                </Col>
                              </React.Fragment>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </Row>
                  </Card>
                </Col>

                <Col span={24}>
                  <Form.Item name="active" valuePropName="checked">
                    <Checkbox>Active</Checkbox>
                  </Form.Item>
                </Col>
                <Form.Item>
                  <NJVButton type="primary" htmlType="submit" loading={loading}>
                    Create
                  </NJVButton>
                </Form.Item>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ChargingStationForm;
