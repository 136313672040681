import { EditOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Row, Select, Skeleton, Space, Table, Tag } from 'antd';
import { default as React, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVEditButton,
  NJVInput,
  NJVSelect,
  NJVTable,
  TitleLevel3,
} from '../../../component/core-component';
import { Constant, UserStatus } from '../../../core/constant';
import Api from '../../../network/api';
import { HTTP_METHOD } from '../../../network/httpMethod';
import { ApiHandler } from '../../../network/network-manager';
import CustomPath from '../../../route/custom-path';
import { MEDIA_TYPE } from '../../../network/mediaType';
import Theme from '../../../component/theme';

const { Option } = Select;

function AdminPage() {
  const pageSize = Constant.pageSize;
  const type = 'Admin';
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState();
  const [usertype, setUserType] = useState();

  const handleSearch = () => {
    fetchData();
  };

  // const handleClick = (e) => {
  //   if (usertype !== 'admin') {
  //     e.preventDefault();
  //     message.error('You do not have permission to create.');
  //   }
  // };

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      fullName: filterData?.searchFullName?.trim() ? filterData?.searchFullName?.trim() : '',
      phoneNumber: filterData?.searchPhoneNumber?.trim() ? filterData?.searchPhoneNumber?.trim() : '',
      status: filterData?.searchStatus?.trim() ? filterData?.searchStatus?.trim() : '',
    };

    ApiHandler({
      url: Api.admin_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => setDataFetching(false));
  };

  useEffect(() => {
    setDataFetching(true);
    fetchData();
  }, []);

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Name',
      key: 'fullName',
      dataIndex: 'fullName',
    },
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
      key: 'emailAddress',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, { status }) => (
        <>
          {status === 'Active' ? (
            <Tag color="#87d068">{UserStatus[status]}</Tag>
          ) : status === UserStatus.Deactivate ? (
            <Tag color="gray">{UserStatus[status]}</Tag>
          ) : (
            <Tag color="#cd201f">{UserStatus[status] ?? UserStatus.Inactive}</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      textAlign: 'center',
      width: 60,
      render: (admin) => (
        <Space>
          <Link to={`${CustomPath.admin_update}/${admin.id}`}>
            <NJVEditButton type="Admin" />
          </Link>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    // fetchData(pageNumber);
    setFilterData((prev) => ({
      ...prev,
      pageNumber,
    }));
  };

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onChangeStatus = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      searchStatus: key,
    }));
  };

  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Admin'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to={CustomPath.admin_form}>
            <NJVAddButton type="Admin" />
          </Link>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by name"
            onChange={(e) => onChangeFilter('searchFullName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by phone"
            onChange={(e) => onChangeFilter('searchPhoneNumber', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVSelect
            bgcolor={Theme.colors.light_gray}
            size="large"
            placeholder="Search by status"
            onChange={onChangeStatus}
            style={{ width: '100%' }}
          >
            {Object.entries(UserStatus).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </NJVSelect>
        </Col>
        <Col span={6}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey={'id'}
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
export default AdminPage;
