import { Button, Col, InputNumber, message, Modal, Row, Skeleton, Space, Table, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { getRowClassName, NJVButton, NJVInput, NJVTable, TitleLevel3 } from '../../component/core-component';
import { Constant } from '../../core/constant';
import { ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { useToggle } from 'react-use';
import { MEDIA_TYPE } from '../../network/mediaType';

const DriverPoints = () => {
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useState(false);
  const [isUpdating, toggleUpdating] = useToggle(false);
  const [searchAssignHub, setSearchAssignHub] = useState('');
  const [searchDriverNo, setSearchDriverNo] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [updateData, setUpdateData] = useState(undefined);
  const [isModelOpen, toggleModelOpen] = useToggle(false);
  const [filterData, setFilterData] = useState();

  const handleSearch = () => {
    fetchData();
  };

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      driverNo: filterData?.searchDriverNumber?.trim() ? filterData?.searchDriverNumber?.trim() : '',
      assignHub: filterData?.searchPhoneNumber?.trim() ? filterData?.searchPhoneNumber?.trim() : '',
    };

    ApiHandler({
      url: Api.drivers_all,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => toggleDataFetching(false));
  };

  useEffect(() => {
    toggleDataFetching();
    fetchData();
  }, []);

  const updatePoints = (formData) => {
    formData = formData ?? updateData;
    toggleSubmitting();
    ApiHandler({
      url: Api.update_driver_points,
      method: HTTP_METHOD.PUT,
      requestData: { ...formData, points: (formData.points ?? 0) + formData.newPoints },
    })
      .then(() => {
        fetchData();
      })
      .catch((error) => message.error(error))
      .finally(() => {
        toggleSubmitting();
        toggleModelOpen(false);
      });
  };

  const handleMultiReset = () => {
    toggleUpdating();

    ApiHandler({
      url: Api.reset_multiple_points,
      method: HTTP_METHOD.POST,
      requestData: { ids: selectedIds },
    })
      .then(() => fetchData(), setSelectedIds([]))
      .finally(() => toggleUpdating(), toggleModelOpen());
  };

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedIds,
    onChange: (selectedRowKeys) => {
      setSelectedIds(selectedRowKeys);
    },
  };

  const onChangeFilter = (key, value) => {
    console.log(key);
    console.log(value);
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Driver No',
      dataIndex: 'driverNo',
    },
    {
      title: 'Current Points',
      dataIndex: 'points',
      render: (v) => <span>{v ?? 0}</span>,
    },
    {
      title: 'Points',
      dataIndex: 'newPoints',
      render: (v, row) => (
        <InputNumber
          value={v ?? 0}
          onChange={(points) =>
            setData((arr) =>
              arr.map((v) => {
                if (v.id === row.id) {
                  return {
                    ...v,
                    newPoints: points,
                  };
                }
                return v;
              }),
            )
          }
        />
      ),
    },
    {
      title: 'Action',
      textAlign: 'center',
      render: (row) => (
        <Space>
          <Tooltip placement="top" title={'Save Changes'} arrow={false}>
            <Button size={'middle'} loading={isSubmitting} type="primary" onClick={() => updatePoints(row)}>
              <SaveOutlined /> Save
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={'Reset Points'} arrow={false}>
            <Button
              size={'middle'}
              loading={isSubmitting}
              type="primary"
              onClick={() => {
                setUpdateData({ ...row, points: 0 });
                toggleModelOpen();
              }}
              danger
            >
              <ReloadOutlined /> Reset
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };
  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Driver Points'} />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by Driver Number"
            onChange={(e) => onChangeFilter('searchDriverNumber', e.target.value)}
          />
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        {selectedIds.length > 0 && (
          <Col span={3}>
            <NJVButton loading={isUpdating} type="primary" onClick={toggleModelOpen}>
              <ReloadOutlined /> Reset
            </NJVButton>
          </Col>
        )}
        <Col span={24}>
          {isFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowSelection={rowSelection}
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={columns}
              dataSource={data}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
      <Modal
        title="Reset Points"
        open={isModelOpen}
        onOk={() => (selectedIds.length === 0 ? updatePoints() : handleMultiReset())}
        confirmLoading={isSubmitting || isUpdating}
        onCancel={toggleModelOpen}
      >
        <p>Are you sure want to reset the driver points?</p>
      </Modal>
    </>
  );
};

export default DriverPoints;
