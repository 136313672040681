const USER_INFO_KEY = 'zeimm-user-info';

export const storeUserInfo = (user) => {
  if (user) {
    localStorage.setItem(USER_INFO_KEY, JSON.stringify(user));
  }
};

export const getUserInfo = () => {
  const user = localStorage.getItem(USER_INFO_KEY);
  return JSON.parse(user);
};

export const logout = () => {
  localStorage.removeItem(USER_INFO_KEY);
  window.location.href = '/';
};
