import { Breadcrumb, Button, Card, Col, Form, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { NJVButton, NJVInput, NJVSelect } from '../../component/core-component';
import Theme from '../../component/theme';
import CustomPath from '../../route/custom-path';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { FaqType, UserType } from '../../core/constant';

function FaqUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const fromRef = useRef();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    findFaq(id);
  }, []);

  const findFaq = async () => {
    await ApiHandler({
      url: Api.faq,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        setData(response);
        fromRef.current.setFieldsValue(response);
      })
      .catch((error) => setLoading(false));
  };

  const updateFaq = (formData) => {
    setLoading(true);

    ApiHandler({
      url: Api.faq,
      method: HTTP_METHOD.PUT,
      requestData: { ...formData, id: data.id },
    })
      .then((response) => {
        backToHome();
      })
      .catch((error) => setLoading(false));
  };

  const backToHome = () => navigate(CustomPath.faq);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.faq}>FAQ</Link>,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'FAQ Form'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form layout="vertical" onFinish={updateFaq} ref={fromRef}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="questionEng"
                    label={'Question English'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput
                      istextareainput={true}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder={'Enter Question English'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="answerEng"
                    label={'Answer English'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput
                      istextareainput={true}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder={'Enter Answer English'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="questionMm"
                    label={'Question Myanmar'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput
                      istextareainput={true}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder={'Enter Question Myanmar'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="answerMm"
                    label={'Answer Myanmar'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput
                      istextareainput={true}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder={'Enter Answer Myanmar'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="faqType" label={'FAQ Type'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVSelect>
                      {Object.entries(FaqType).map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="userType" label={'User Type'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVSelect>
                      {Object.entries(UserType).map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Form.Item>
                  <NJVButton type="primary" htmlType="submit" loading={loading}>
                    Update
                  </NJVButton>
                </Form.Item>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default FaqUpdate;
