import { Col, message, Row, Skeleton, Space, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import {
  getRowClassName,
  NJVButton,
  NJVInput,
  NJVRangePicker,
  NJVTable,
  TitleLevel3,
} from '../../component/core-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const TripHistory = () => {
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);
  const [tripId, setTripId] = useState();
  const [filterData, setFilterData] = useState();

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 55,
      fixed: 'left',
    },
    {
      title: 'Trip ID',
      dataIndex: 'tripNumber',
      fixed: 'left',
      width: 70,
    },
    {
      title: 'Date and Time',
      dataIndex: 'time',
      width: 150,
    },
    {
      title: 'Pickup Location',
      dataIndex: 'pickupLocation',
      width: 150,
    },
    {
      title: 'Drop-off Location',
      dataIndex: 'dropOfLocation',
    },
    {
      title: 'Distance Traveled',
      dataIndex: 'distanceTraveled',
    },
    {
      title: 'Fare',
      dataIndex: 'fare',
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
    },
    {
      title: 'Driver Name',
      dataIndex: 'driverName',
    },
    {
      title: 'Vehicle License Number',
      dataIndex: 'vehicleLicenseNumber',
    },
    {
      title: 'Passenger Name',
      dataIndex: 'passengerName',
    },
    {
      title: 'Passenger PhoneNumber',
      dataIndex: 'passengerPhoneNumber',
      width: 150,
    },
    {
      title: 'Trip Status',
      dataIndex: 'tripStatus',
      render: (status) => (
        <>{status === 'Completed' ? <Tag color="#87d068">{status}</Tag> : <Tag color="#cd201f">{status}</Tag>}</>
      ),
    },
    {
      title: 'View Details',
      textAlign: 'view_details',
      render: (history) => (
        <Link to={`${CustomPath.trip_history_detail}/${history.id}`}>
          <Space>
            <a>View Detail</a>
          </Space>
        </Link>
      ),
      fixed: 'right',
    },
  ];

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      tripId: filterData?.searchTripId?.trim() ? filterData?.searchTripId?.trim() : '',
      licenseNumber: filterData?.searchLicenseNumber?.trim() ? filterData?.searchLicenseNumber?.trim() : '',
      name: filterData?.searchName?.trim() ? filterData?.searchName?.trim() : '',
      phoneNumber: filterData?.searchPhoneNumber?.trim() ? filterData?.searchPhoneNumber?.trim() : '',
    };

    ApiHandler({
      url: Api.trip_history_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => toggleDataFetching(false));
  };

  const handleSearch = () => {
    fetchData();
  };

  useEffect(() => {
    toggleDataFetching();
    fetchData();
  }, []);

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Trip History'} />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by TripID"
            onChange={(e) => onChangeFilter('searchTripId', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by Vehicle License Plate Number"
            onChange={(e) => onChangeFilter('searchLicenseNumber', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVRangePicker bgcolor="#f1f1f1" />
        </Col>
        <Col span={6}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by DriverName"
            onChange={(e) => onChangeFilter('searchName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by DriverPhoneNumber"
            onChange={(e) => onChangeFilter('searchPhoneNumber', e.target.value)}
          />
        </Col>
        <Col span={24}>
          {isFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={columns}
              dataSource={data}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              rowClassName={getRowClassName}
              scroll={{
                x: 1600,
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default TripHistory;
