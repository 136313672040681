import { Button, Col, message, Modal, Row, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';
import { NJVButton, NJVSelect, TitleLevel3 } from '../../component/core-component';
import Theme from '../../component/theme';
import CustomPath from '../../route/custom-path';
import { Link } from 'react-router-dom';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const convertDateToDayJs = (targetDate) => {
  if (targetDate) {
    const date = dayjs(new Date(targetDate));
    return date.format('YYYY-MM-DD HH:mm');
  }
  return null;
};

const convertStringDateToDate = (strDate) => {
  if (strDate) {
    const dayjsDate = dayjs(strDate, 'YYYY-MM-DD HH:mm');
    return dayjsDate.toDate();
  }
  return null;
};

let stompClient = null;
const DriverVehicleAssignWithCalendar = () => {
  const [events, setEvents] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleMonthYear, setVehicleMonthYear] = useState(null);
  const [socketConnected, setSocketConnected] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openUnavailableAssignDateModal, setOpenUnavailableAssignDateModal] = useState(false);
  const [openViewDetailModal, setOpenViewDetailModal] = useState(false);
  const [openUnassignConfirmationModal, setOpenUnassignConfirmationModal] = useState(false);
  const [unavailableAssignDates, setUnavailableAssignDates] = useState([]);

  const eventsRef = useRef([]);

  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    if (!socketConnected) {
      connectSocket();
    }
    eventsRef.current = events;
  }, [events]);

  const connectSocket = () => {
    setSelectedDate(new Date());
    try {
      const Sock = new SockJS('http://localhost:9093/ws');
      stompClient = over(Sock);
      stompClient.connect(
        {},
        function () {
          setSocketConnected(true);
          stompClient.subscribe('/driver-vehicle-assign/vehicles', onReceiveVehicle);
          stompClient.subscribe('/driver-vehicle-assign/drivers', onReceiveDriver);
          stompClient.subscribe('/driver-vehicle-assign/assignedDriverToDate', onDriverAssign);
          stompClient.subscribe('/driver-vehicle-assign/unAssignDriver', onDriverUnAssign);

          stompClient.subscribe('/driver-vehicle-assign/vehicleMonthYear', onReceiveVehicleMonthYear);
          stompClient.send('/app/getVehicles', {}, {});
        },
        function () {
          console.log('Connection Error');
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onReceiveVehicle = (payload) => {
    const payloadData = JSON.parse(payload?.body);
    const temp = payloadData?.map((item) => {
      item['label'] = item.licenseNumber;
      item['value'] = item.id;
      item['data'] = item;
      return item;
    });
    setVehicles(temp);
  };
  const onReceiveDriver = (payload) => {
    const payloadData = JSON.parse(payload?.body);
    const temp = payloadData?.map((item) => {
      item['label'] = item.fullName + ' [' + item?.driverNo + ']';
      item['value'] = item.id;
      item['data'] = item;
      return item;
    });
    setDrivers(temp);
  };
  const onDriverAssign = (payload) => {
    const payloadData = JSON.parse(payload?.body);
    const newEvent = {
      id: payloadData?.id,
      title: payloadData?.driverDTO?.fullName + ' [' + payloadData?.driverDTO?.driverNo + ']',
      start: convertStringDateToDate(payloadData?.startDateTime),
      end: convertStringDateToDate(payloadData?.endDateTime),
      driverId: payloadData?.driverId,
    };
    let unavailableAssignDateRange = false;
    let isFountExistingAssigment = false;
    if (payloadData?.assignType === 'DATE_RANGE' && payloadData?.unavailableAssignDate) {
      setUnavailableAssignDates(payloadData?.unavailableAssignDate);
      setOpenUnavailableAssignDateModal(true);
      unavailableAssignDateRange = true;
    }
    if (!unavailableAssignDateRange) {
      const newEvents = [];
      eventsRef?.current?.map((item) => {
        if (item?.id === newEvent?.id) {
          item['start'] = newEvent?.start;
          item['end'] = newEvent?.end;
          isFountExistingAssigment = true;
        }
        newEvents.push(item);
      });
      if (!isFountExistingAssigment) {
        newEvents.push(newEvent);
      }
      setEvents(newEvents);
    }

    setVehicleMonthYear({
      id: payloadData?.vehicleMonthYear?.id,
      vehicleId: payloadData?.vehicleMonthYear?.vehicleId,
      month: payloadData?.vehicleMonthYear?.month,
      year: payloadData?.vehicleMonthYear?.year,
    });
  };

  const onDriverUnAssign = (payload) => {
    const payloadData = JSON.parse(payload?.body);
    const newEvents = [];
    eventsRef?.current?.map((item) => {
      if (item?.id !== payloadData?.id) {
        newEvents.push(item);
      }
    });
    setEvents(newEvents);
  };
  const onReceiveVehicleMonthYear = (payload) => {
    const payloadData = JSON.parse(payload?.body);
    if (payloadData) {
      setVehicleMonthYear({
        id: payloadData?.id,
        vehicleId: payloadData?.vehicleId,
        month: payloadData?.month,
        year: payloadData?.year,
      });
      const newEvents = payloadData?.driverAssignmentDTOS.map((driverAssignment) => {
        return {
          id: driverAssignment?.id,
          title: driverAssignment?.driverDTO.fullName + ' [' + driverAssignment?.driverDTO?.driverNo + ']',
          start: convertStringDateToDate(driverAssignment?.startDateTime),
          end: convertStringDateToDate(driverAssignment?.endDateTime),
          driverId: driverAssignment?.driverId,
        };
      });
      setEvents(newEvents);
      eventsRef.current = newEvents;
    }
  };
  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    console.log('moveEvent');
    const { allDay } = event;
    if (!allDay && droppedOnAllDaySlot) {
      event.allDay = true;
    } else if (allDay && !droppedOnAllDaySlot) {
      event.allDay = false;
    }
    setEvents((prev) => {
      const idx = prev.indexOf(event);
      const updatedEvent = { ...event, start, end };
      const nextEvents = [...prev];
      nextEvents.splice(idx, 1, updatedEvent);
      return nextEvents;
    });
  };

  const resizeEvent = ({ event, start, end }) => {
    const currentMonthYear = new Date(selectedDate);
    const startDate = new Date(start);
    const endDate = new Date(end);
    const formattedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0);
    const formattedEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59);
    const requestObject = {
      id: event.id,
      startDateTime: convertDateToDayJs(formattedStartDate),
      endDateTime: convertDateToDayJs(formattedEndDate),
      assignType: 'DATE_RANGE',
      driverId: event.driverId,
      vehicleMonthYear: {
        id: vehicleMonthYear?.id,
        vehicleId: selectedVehicle,
        year: currentMonthYear.getFullYear(),
        month: currentMonthYear.getMonth() + 1,
      },
    };
    stompClient.send('/app/assignedDriverToDate', {}, JSON.stringify(requestObject));
  };

  const handleSelectSlot = (slotInfo) => {
    const date = dayjs(new Date(slotInfo.start));
    const today = dayjs().startOf('day');

    if (date.isBefore(today)) {
      message.error('You cannot assign to the day before.');
      return;
    }

    if (selectedVehicle) {
      setSelectedDate(slotInfo.start);
      stompClient.send('/app/getDrivers', {}, JSON.stringify({ date: date.format('YYYY-MM-DD') }));
      setOpenModal(true);
    } else {
      message.error('Please select vehicle first');
    }
  };

  const onSelectDriver = (value, driver) => {
    const date = new Date(selectedDate);
    const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0);
    const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59);
    const requestObject = {
      driverId: driver.id,
      startDateTime: convertDateToDayJs(startDate),
      endDateTime: convertDateToDayJs(endDate),
      assignType: 'SINGLE_DATE',
      vehicleMonthYear: {
        id: vehicleMonthYear?.id,
        vehicleId: selectedVehicle,
        year: date.getFullYear(),
        month: date.getMonth() + 1,
      },
    };
    stompClient.send('/app/assignedDriverToDate', {}, JSON.stringify(requestObject));
    setSelectedDriver(null);
    setSelectedDate(null);
    setOpenModal(false);
  };
  const handleOnView = (event, slotInfo) => {
    setSelectedEvent(event);
    setOpenViewDetailModal(true);
    console.log(slotInfo);
  };

  const handleNavigate = (currentMonth) => {
    setSelectedDate(currentMonth);
    const requestObject = {
      vehicleId: selectedVehicle,
      month: currentMonth.getMonth() + 1,
      year: currentMonth.getFullYear(),
    };
    stompClient.send('/app/getVehicleMonthYear', {}, JSON.stringify(requestObject));
  };
  const onSelectVehicle = (vehicleId) => {
    setSelectedVehicle(vehicleId);
    setEvents([]);
    const date = new Date(selectedDate);
    const requestObject = {
      vehicleId,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    stompClient.send('/app/getVehicleMonthYear', {}, JSON.stringify(requestObject));
  };

  const unAssignDriver = () => {
    const requestObject = {
      id: selectedEvent?.id,
    };
    stompClient.send('/app/unAssignDriver', {}, JSON.stringify(requestObject));
    setOpenUnassignConfirmationModal(false);
    setOpenViewDetailModal(false);
    setSelectedEvent(null);
  };
  return (
    <div style={{ margin: 10 }}>
      <Modal footer={null} title="Select Driver" open={openModal} onCancel={() => setOpenModal(false)}>
        <NJVSelect
          size="large"
          placeholder="Select driver"
          onChange={(value, obj) => onSelectDriver(value, obj)}
          value={selectedDriver}
          bgcolor={Theme.colors.light_gray}
          style={{ width: '100%' }}
          options={drivers}
        />
      </Modal>
      <Modal
        footer={null}
        title="Unavailable Assign Date"
        open={openUnavailableAssignDateModal}
        onCancel={() => {
          setOpenUnavailableAssignDateModal(false);
          setUnavailableAssignDates([]);
        }}
      >
        <Space>
          {unavailableAssignDates?.map((item) => (
            <Tag key={item}>{item}</Tag>
          ))}
        </Space>
      </Modal>
      <Modal
        footer={null}
        title="Assigned Detail"
        open={openViewDetailModal}
        onCancel={() => {
          setOpenViewDetailModal(false);
        }}
      >
        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col span={12}>Driver</Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {selectedEvent?.title}
          </Col>
          <Col span={24}>
            <Space>
              <span>
                From <Tag>{dayjs(new Date(selectedEvent?.start)).format('YYYY-MM-DD')}</Tag>
              </span>
              <span>
                To <Tag>{dayjs(new Date(selectedEvent?.end)).format('YYYY-MM-DD')}</Tag>
              </span>
            </Space>
          </Col>
          <Col span={24} style={{ marginTop: 30 }}>
            <NJVButton
              loading={openUnassignConfirmationModal}
              type="primary"
              onClick={() => setOpenUnassignConfirmationModal(true)}
            >
              Unassign
            </NJVButton>
          </Col>
        </Row>
      </Modal>
      <Modal
        footer={null}
        title="Unassign Confirmation"
        open={openUnassignConfirmationModal}
        onCancel={() => {
          setOpenUnassignConfirmationModal(false);
        }}
      >
        <NJVButton type="primary" onClick={() => unAssignDriver()}>
          Unassign Now
        </NJVButton>
      </Modal>
      <Row gutter={[16, 16]}>
        <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
          <Link to={CustomPath.admin}>
            <Button type="primary" style={{ marginRight: 10 }}>
              Back
            </Button>
          </Link>

          <TitleLevel3 label={'Driver Vehicle Assignment'} />
        </Col>
        <Col span={6} style={{ textAlign: 'end' }}>
          <NJVSelect
            size="large"
            placeholder="Select vehicle"
            onChange={onSelectVehicle}
            value={selectedVehicle}
            bgcolor={Theme.colors.light_gray}
            style={{ width: '100%' }}
            options={vehicles}
          />
        </Col>
        <Col span={24}>
          <DnDCalendar
            defaultDate={selectedDate}
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            // onEventDrop={moveEvent}
            resizable
            selectable
            onEventResize={resizeEvent}
            onSelectSlot={handleSelectSlot}
            onDoubleClickEvent={handleOnView}
            onNavigate={handleNavigate}
            style={{ height: 680 }}
            views={['month', 'week']}
          />
        </Col>
      </Row>
    </div>
  );
};
export default DriverVehicleAssignWithCalendar;
