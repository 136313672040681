import PickupFare from '../page/pricing/pickup-fare';
import CustomPath from '../route/custom-path';
export const mapBoxAccessToken =
  'pk.eyJ1Ijoibmp2bW0iLCJhIjoiY2xuMTR6cjlqMDhhYTJqb2kwbmtudDdiciJ9.we-mhra0h3nMtaH1KU0WpQ';
export const awsPlaceKey =
  'v1.public.eyJqdGkiOiI4MWVhYWQxZi1kODJjLTQzYmMtODk5ZC1jODBiNzM4MDEyYzcifY6DFj0bSAOf_PmKuGq1SsnOWGu0UPE0t1BnPB0S-lnH0tQ8_mhA1W-057gLZ8FhRhAyT9wMDq61tBJSUekJqEPAuhTUHmaWLLVtXePxrQmrbR1WWszuTDU70FrsSXJYkxx9YZLeRVXfx5S4i9Oat6Gz4lzoFQruw3qIQgxyAf8KNJaMsw1l67l8Emx32UjXiEpx80oIEGt_s7xDFKrZ-novWYjrYAQuL4LvAMPCrC9x9kxqEdG7ksbAIVXmC4XeU6t8iYOVkWsYGywTtn1DoHr513PZHzOHgoHW0dQ59sL4wVr8bLiFKoQdNcFhCGTLPAi61UXsh8EPOP6114escpw.MzRjYzZmZGUtZmY3NC00NDZiLWJiMTktNTc4YjUxYTFlOGZi';
export const Constant = {
  pageSize: 20,
  nrcValueRegex: /^(\d+)\/([A-Za-z]+)\((\w)\)(\d+)$/,
  paymentMethod: Object.freeze({
    CreditCard: 'Credit Card',
    Cash: 'Cash',
    PayPal: 'PayPal',
  }),
  userStatus: Object.freeze({
    Active: 'Active',
    Inactive: 'Inactive',
    // Pending: 'Pending',
    // Aprroved: 'Aprroved',
    // Reject: 'Reject',
  }),
  insuranceCompany: Object.freeze({
    GGI: 'GGI',
    GWI: 'GWI',
    ALifeInsurance: 'ALife Insurance',
    FNI: 'FNI',
    MyanmarInsurance: 'Myanmar Insurance',
  }),
  vehicleType: Object.freeze({
    Vans: 'Vans',
    BoxTrucks: 'Box Trucks',
  }),
};

export const PromotionType = Object.freeze({
  FlatAmount: 'Flat Amount',
  Percentage: 'Percentage',
});

export const FaqType = Object.freeze({
  GeneralInfo: 'General Info',
  Account: 'Account',
  Booking: 'Booking',
  Payment: 'Payment',
  // Others FAQ type here
});

export const UserType = Object.freeze({
  Driver: 'Driver',
  Passenger: 'Passenger',
});

export const PaymentMethod = Object.freeze({
  CreditCard: 'Credit Card',
  Cash: 'Cash',
  PayPal: 'PayPal',
});

export const UserStatus = Object.freeze({
  Active: 'Active',
  Inactive: 'Inactive',
  Deactivate: 'Deactivate',
});

export const Gender = Object.freeze({
  Male: 'Male',
  Female: 'Female',
});

export const DayOfWeek = Object.freeze({
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
});

export const OpeningHoursStatus = Object.freeze({
  Open: 'Open',
  Closed: 'Closed',
});

export const InsuranceCompany = Object.freeze({
  GGI: 'GGI',
  GWI: 'GWI',
  ALifeInsurance: 'ALife Insurance',
  FNI: 'FNI',
  MyanmarInsurance: 'Myanmar Insurance',
});

export const VehicleType = Object.freeze({
  Sedan: 'Sedan',
  Hatchback: 'Hatchback',
  StationWagon: 'Station Wagon',
  MPV: 'MPV',
  SUV: 'SUV',
});

export const Regions = Object.freeze({
  YGN: 'Yangon',
  MDY: 'Mandalay',
  NPT: 'Naypyidaw',
  BGO: 'Bago',
  MGW: 'Magway',
  SGG: 'Sagaing',
  TNI: 'Tanintharyi',
  AYW: 'Ayeyarwady',
  KCN: 'Kachin',
  KYH: 'Kayah',
  KYN: 'Kayin',
  CHK: 'Chin',
  MNR: 'Mon',
  RKH: 'Rakhine',
  SHN: 'Shan',
});

export const sosStatus = Object.freeze({
  Pending: 'Pending',
  InProgress: 'In Progress',
  Resolved: 'Resolved',
});

export const userPermissions = [
  { menuName: 'Admin', read: true, write: true },
  { menuName: 'Role', read: false },
  { menuName: 'Driver', read: true, write: true },
  { menuName: 'Vehicle', read: false },
  { menuName: 'Availability_Check', read: true },
  { menuName: 'Zone', read: true, write: true },
  { menuName: 'Promotion', read: false },
  { menuName: 'Trip_History', read: true },
];

export const TEST_SIDE_MENU = {
  // { route: CustomPath.admin, name: 'Admin' },
  // { route: CustomPath.role, name: 'Role' },
  // { route: CustomPath.driver, name: 'Driver' },
  // { route: CustomPath.passenger, name: 'Passenger' },
  // { route: CustomPath.vehicle, name: 'Vehicle' },
  // { route: CustomPath.driver_vehicle_assign, name: 'Assign' },
  // { route: CustomPath.booking, name: 'Booking' },
  // { route: CustomPath.driver_vehicle_availability_check, name: 'Availability Check' },
  // { route: CustomPath.region, name: 'Region' },
  // { route: CustomPath.base_fare, name: 'Base Fare' },
  // { route: CustomPath.pickup_fare, name: 'Pickup Fare' },
  // { route: CustomPath.waiting_charges, name: 'Waiting Charges' },
  // { route: CustomPath.distance_fare, name: 'Distance Fare' },
  // { route: CustomPath.time_fare, name: 'Time Fare' },
  // { route: CustomPath.traffic_charges, name: 'Traffic Charges' },
  // { route: CustomPath.weather_condition, name: 'Weather Condition' },
  // { route: CustomPath.peak_hour, name: 'Peak Hour' },
  // { route: CustomPath.zone, name: 'Zone' },
  // { route: CustomPath.zone_pricing, name: 'Zone Pricing' },
  // { route: CustomPath.notification_channel, name: 'Notification Channel' },
  // { route: CustomPath.notification_push, name: 'Send Notification' },
  // { route: CustomPath.notification_history, name: 'Notification History' },
  // { route: CustomPath.faq, name: 'FAQ' },
  // { route: CustomPath.driver_points, name: 'Driver Points Management' },
  // { route: CustomPath.charging_station, name: 'Charging Station' },
  // { route: CustomPath.sos, name: 'SOS Alerts' },
  // { route: CustomPath.promotion, name: 'Promotion Management' },
  // { route: CustomPath.trip_history, name: 'Trip History' },

  Admin: { route: CustomPath.admin, name: 'Admin', header: 'User Management' },
  Role: { route: CustomPath.role, name: 'Role', header: 'User Management' },
  Passenger: { route: CustomPath.passenger, name: 'Passenger', header: 'User Management' },
  Driver: { route: CustomPath.driver, name: 'Driver', header: 'Fleet Management' },
  Vehicle: { route: CustomPath.vehicle, name: 'Vehicle', header: 'Fleet Management' },
  Assign: { route: CustomPath.driver_vehicle_assign, name: 'Assign', header: 'Fleet Management' },
  Availability_Check: {
    route: CustomPath.driver_vehicle_availability_check,
    name: 'Availability Check',
    header: 'Fleet Management',
  },
  Driver_Point_Management: {
    route: CustomPath.driver_points,
    name: 'Driver Points Management',
    header: 'Fleet Management',
  },
  Trip_History: { route: CustomPath.trip_history, name: 'Trip History', header: 'Operations' },
  Booking: { route: CustomPath.booking, name: 'Booking', header: 'Operations' },
  Regions: { route: CustomPath.region, name: 'Region', header: 'Region & Zone' },
  Zone: { route: CustomPath.zone, name: 'Zone', header: 'Region & Zone' },
  Notification_Channel: {
    route: CustomPath.notification_channel,
    name: 'Notification Channel',
    header: 'Notification Management',
  },
  Notification_Push: {
    route: CustomPath.notification_push,
    name: 'Send Notification',
    header: 'Notification Management',
  },
  Notification_History: {
    route: CustomPath.notification_history,
    name: 'Notification History',
    header: 'Notification Management',
  },
  FAQ: { route: CustomPath.faq, name: 'FAQ', header: 'Support' },
  Charging_Station: { route: CustomPath.charging_station, name: 'Charging Station', header: 'Support' },
  SOS_Alerts: { route: CustomPath.sos, name: 'SOS Alerts', header: 'Alerts' },
  Promotion: { route: CustomPath.promotion, name: 'Promotion', header: 'Marketing' },
  Pickup_Fare: { route: CustomPath.pickup_fare, name: 'Pickup Fare', header: 'Pricing Management' },
  waiting_Charges: { route: CustomPath.waiting_charges, name: 'Waiting Charges', header: 'Pricing Management' },
  Distance_Fare: { route: CustomPath.distance_fare, name: 'Distance Fare', header: 'Pricing Management' },
  Time_Fare: { route: CustomPath.time_fare, name: 'Time Fare', header: 'Pricing Management' },
  Peak_Hour: { route: CustomPath.peak_hour, name: 'Peak Hour', header: 'Pricing Management' },
};

export const MENU = Object.entries(TEST_SIDE_MENU)
  .filter(([key, value]) => {
    const permission = userPermissions.find((p) => p.menuName === key);
    return permission && permission.read === true;
  })
  .map(([key, value]) => value);

export const SIDE_MENU = [
  { route: CustomPath.admin, name: 'Admin', header: 'User Management' },
  { route: CustomPath.role, name: 'Role', header: 'User Management' },
  { route: CustomPath.passenger, name: 'Passenger', header: 'User Management' },
  { route: CustomPath.driver, name: 'Driver', header: 'Fleet Management' },
  { route: CustomPath.vehicle, name: 'Vehicle', header: 'Fleet Management' },
  { route: CustomPath.driver_vehicle_assign, name: 'Assign', header: 'Fleet Management' },
  { route: CustomPath.driver_vehicle_availability_check, name: 'Availability Check', header: 'Fleet Management' },
  { route: CustomPath.driver_points, name: 'Driver Points Management', header: 'Fleet Management' },
  { route: CustomPath.trip_history, name: 'Trip History', header: 'Operations' },
  { route: CustomPath.booking, name: 'Booking', header: 'Operations' },
  { route: CustomPath.region, name: 'Region', header: 'Region & Zone' },
  { route: CustomPath.zone, name: 'Zone', header: 'Region & Zone' },
  { route: CustomPath.notification_channel, name: 'Notification Channel', header: 'Notification Management' },
  { route: CustomPath.notification_push, name: 'Send Notification', header: 'Notification Management' },
  { route: CustomPath.notification_history, name: 'Notification History', header: 'Notification Management' },
  { route: CustomPath.faq, name: 'FAQ', header: 'Support' },
  { route: CustomPath.charging_station, name: 'Charging Station', header: 'Support' },
  { route: CustomPath.sos, name: 'SOS Alerts', header: 'Alerts' },
  { route: CustomPath.promotion, name: 'Promotion', header: 'Marketing' },
  { route: CustomPath.pickup_fare, name: 'Pickup Fare', header: 'Pricing Management' },
  { route: CustomPath.waiting_charges, name: 'Waiting Charges', header: 'Pricing Management' },
  { route: CustomPath.distance_fare, name: 'Distance Fare', header: 'Pricing Management' },
  { route: CustomPath.time_fare, name: 'Time Fare', header: 'Pricing Management' },
  { route: CustomPath.peak_hour, name: 'Peak Hour', header: 'Pricing Management' },

  //   { route: CustomPath.admin, name: 'Admin', header: 'User Management', key: 'ADMIN' },
  //   { route: CustomPath.role, name: 'Role', header: 'User Management', key: 'ROLE' },
  //   { route: CustomPath.passenger, name: 'Passenger', header: 'User Management', key: 'PASSENGER' },
  //   { route: CustomPath.driver, name: 'Driver', header: 'Fleet Management', key: 'DRIVER' },
  //   { route: CustomPath.vehicle, name: 'Vehicle', header: 'Fleet Management', key: 'VEHICLE' },
  //   { route: CustomPath.driver_vehicle_assign, name: 'Assign', header: 'Fleet Management', key: 'ASSIGN' },
  //   {
  //     route: CustomPath.driver_vehicle_availability_check,
  //     name: 'Availability Check',
  //     header: 'Fleet Management',
  //     key: 'AVAILABILITY_CHECK',
  //   },
  //   {
  //     route: CustomPath.driver_points,
  //     name: 'Driver Points Management',
  //     header: 'Fleet Management',
  //     key: 'DRIVER_POINTS_MANAGEMENT',
  //   },
  //   { route: CustomPath.trip_history, name: 'Trip History', header: 'Operations', key: 'TRIP_HISTORY' },
  //   { route: CustomPath.booking, name: 'Booking', header: 'Operations', key: 'BOOKING' },
  //   { route: CustomPath.region, name: 'Region', header: 'Region & Zone', key: 'REGION' },
  //   { route: CustomPath.zone, name: 'Zone', header: 'Region & Zone', key: 'ZONE' },
  //   {
  //     route: CustomPath.notification_channel,
  //     name: 'Notification Channel',
  //     header: 'Notification Management',
  //     key: 'NOTIFICATION_CHANNEL',
  //   },
  //   {
  //     route: CustomPath.notification_push,
  //     name: 'Send Notification',
  //     header: 'Notification Management',
  //     key: 'SEND_NOTIFICATION',
  //   },
  //   {
  //     route: CustomPath.notification_history,
  //     name: 'Notification History',
  //     header: 'Notification Management',
  //     key: 'NOTIFICATION_HISTORY',
  //   },
  //   { route: CustomPath.faq, name: 'FAQ', header: 'Support', key: 'FAQ' },
  //   { route: CustomPath.charging_station, name: 'Charging Station', header: 'Support', key: 'CHARGING_STATION' },
  //   { route: CustomPath.sos, name: 'SOS Alerts', header: 'Alerts', key: 'SOS_ALERTS' },
  //   { route: CustomPath.promotion, name: 'Promotion', header: 'Marketing', key: 'PROMOTION' },
  //   {
  //     route: CustomPath.weather_condition,
  //     name: 'Weather Condition',
  //     header: 'Pricing Management',
  //     key: 'WEATHER_CHARGES',
  //   },
  //   { route: CustomPath.pickup_fare, name: 'Pickup Fare', header: 'Pricing Management', key: 'PICKUP_FARE' },
  //   { route: CustomPath.waiting_charges, name: 'Waiting Charges', header: 'Pricing Management', key: 'WAITING_CHARGES' },
  //   { route: CustomPath.distance_fare, name: 'Distance Fare', header: 'Pricing Management', key: 'DISTANCE_FARE' },
  //   { route: CustomPath.time_fare, name: 'Time Fare', header: 'Pricing Management', key: 'TIME_FARE' },
  //   { route: CustomPath.traffic_charges, name: 'Traffic Charges', header: 'Pricing Management', key: 'TRAFFIC_CHARGES' },
  //   { route: CustomPath.peak_hour, name: 'Peak Hour', header: 'Pricing Management', key: 'PEAK_HOUR' },
];

export const colorConstant = [
  '#FF5733',
  '#3357FF',
  '#FF33A1',
  '#A133FF',
  '#FF8333',
  '#33FF83',
  '#8333FF',
  '#FF3383',
  '#33A1FF',
  '#57FF33',
  '#FF5733',
  '#5733FF',
  '#A1FF33',
  '#FF33A1',
  '#3383FF',
  '#83FF33',
  '#33FFA1',
  '#FFA133',
  '#33FF33',
  '#33FF57',
];

export const PricingStatus = Object.freeze({
  PickupFare: 'Pickup Fare',
  WaitingCharges: 'Waiting Charges',
  DistanceFare: 'Distance Fare',
  TimeFare: 'Time Fare',
});

export const WeatherStatus = Object.freeze({
  Thunderstorm: 'Thunderstorm',
  Drizzle: 'Drizzle',
  Rain: 'Rain',
  Clouds: 'Clouds',
});
