import axios from 'axios';
import AwsApi from '../network/aws-api';
import { awsPlaceKey } from '../core/constant';
import { MEDIA_TYPE } from '../network/mediaType';

const axiosInstance = axios.create({
  timeout: 120000,
});

export const AwsPlaceFinder = async (placeName) => {
  let responseData;
  const requestBody = {
    FilterCountries: ['MMR'],
    Language: 'MMR',
    MaxResults: 15,
    Text: placeName,
  };
  const requestParams = { key: awsPlaceKey };
  await axiosInstance
    .post(AwsApi.aws_place, requestBody, { params: requestParams }, { headers: { 'Content-Type': MEDIA_TYPE.JSON } })
    .then((response) => {
      responseData = response?.data;
    })
    .catch((error) => {
      throw error;
    });
  return responseData;
};

export const AwsPlaceDetail = async (placeId) => {
  const finalUrl = AwsApi.aws_place_detail + '/' + placeId;
  let responseData;
  const requestParams = { key: awsPlaceKey };
  await axiosInstance
    .get(finalUrl, { params: requestParams })
    .then((response) => {
      responseData = response?.data;
    })
    .catch((error) => {
      throw error;
    });
  return responseData;
};
