const Theme = {
  colors: {
    primary: '#c2002f',
    success: '#28a745',
    card_bg_color: '#f5f5f5',
    system_blue: '#4096ff',
    light_gray: '#f5f5f5',
    light_gray_2: '#f9f9f9',
  },
};
export default Theme;
