import { message } from 'antd';
import axios from 'axios';
import Api from './api';
import { HTTP_METHOD } from './httpMethod';
import { getUserInfo, logout, storeUserInfo } from '../core/auth';

const axiosInstance = axios.create({
  baseURL: Api.host,
  timeout: 120000,
});

const axiosInstanceNoAuth = axios.create({
  baseURL: Api.host,
  timeout: 120000,
});

// const shouldRefreshToken = (userInfo) => {
//   try {
//     const currentTime = Math.floor(Date.now() / 1000);
//     alert(userInfo.expireIn);
//     return currentTime >= userInfo.expireIn;
//   } catch (error) {
//     console.error('Invalid token', error);
//     return true;
//   }
// };

axiosInstanceNoAuth.interceptors.request.use(
  async (config) => {
    // const currentLanCode = cookies.get("i18next") || "en"
    // config.headers = {
    //     'Accept-Language': currentLanCode
    // }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.request.use(
  async (config) => {
    const user = getUserInfo();
    if (user) {
      config.headers = {
        Authorization: `Bearer ${user.accessToken}`,
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      if (error.config.url === Api.refresh_token) {
        message.error('Your login session is expired. Please login again!');
        logout();
        return;
      }
      const user = getUserInfo();
      if (user) {
        const accessToken = await axiosInstance.post(Api.refresh_token, {
          refreshToken: user.refreshToken,
        });
        if (accessToken) {
          storeUserInfo({ ...user, accessToken });
        } else {
          message.error('Your login session is expired. Please login again!');
          logout();
        }
      }
    }
    // if (error.response.status === 401) {
    //     try {
    //         const responseData = await RefreshToken();
    //         LOC_storeToken(responseData)
    //         axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${responseData.access_token}`;
    //         return axiosInstance(error.config);
    //     } catch (refreshError) {
    //         message.destroy()
    //         message.error("Login expired! Please login again!")
    //         LOC_clear_token();
    //         window.location.href = '/'
    //     }
    // } else if (error.response.status === 500) {
    //     if (error.response && error.response.data && error.response.data.code) {
    //         let code = error.response.data.code
    //         if (code === '045') {
    //             message.error(i18next.t("user_address_duplicate"))
    //         } else {
    //             throw error
    //         }
    //     }
    // }
    if (error.response.status === 500) {
      message.error(error.response.data.message);
    }
    return Promise.reject(error);
  },
);

export const ApiHandler = async ({
  url,
  method,
  mediaType,
  requestData,
  specificId,
  requestParams,
  customMessage,
  disableShowMessage,
  shouldDestroyPreviousMessage = false,
}) => {
  try {
    let responseData = null;
    let response = null;
    const finalUrl = specificId ? `${url}/${specificId}` : url;
    const header = { headers: { 'Content-Type': mediaType } };
    switch (method) {
      case HTTP_METHOD.GET:
        response = await axiosInstance.get(finalUrl, { params: requestParams });
        responseData = response.data;
        break;
      case HTTP_METHOD.POST:
        responseData = await axiosInstance.post(
          finalUrl,
          requestData,
          {
            params: requestParams,
          },
          header,
        );
        break;
      case HTTP_METHOD.PUT:
        responseData = await axiosInstance.put(finalUrl, requestData, header, {
          params: requestParams,
        });
        break;
      case HTTP_METHOD.DELETE:
        responseData = await axiosInstance.delete(finalUrl);
        break;
      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }
    shouldDestroyPreviousMessage && message.destroy();

    if (!disableShowMessage) {
      if (customMessage) {
        message.success(customMessage);
      } else {
        switch (method) {
          case HTTP_METHOD.POST:
            message.success('Successfully Created');
            break;
          case HTTP_METHOD.PUT:
            message.success('Successfully Updated');
            break;
          case HTTP_METHOD.DELETE:
            message.success('Successfully Deleted');
            break;
          default:
            break;
        }
      }
    }
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const ApiHandlerWithNoAuth = async ({
  url,
  method,
  mediaType,
  requestData,
  specificId,
  requestParams,
  customMessage,
  disableShowMessage,
}) => {
  try {
    let responseData = null;
    let response = null;
    const finalUrl = specificId ? `${url}/${specificId}` : url;
    const header = { headers: { 'Content-Type': mediaType } };

    switch (method) {
      case HTTP_METHOD.GET:
        response = await axiosInstanceNoAuth.get(finalUrl, {
          params: requestParams,
        });
        responseData = response.data;
        break;
      case HTTP_METHOD.POST:
        responseData = await axiosInstanceNoAuth.post(finalUrl, requestData, header, { params: requestParams });
        break;
      case HTTP_METHOD.PUT:
        responseData = await axiosInstanceNoAuth.put(finalUrl, requestData, header, { params: requestParams });
        break;
      case HTTP_METHOD.DELETE:
        responseData = await axiosInstanceNoAuth.delete(finalUrl);
        break;
      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }
    message.destroy();
    if (!disableShowMessage) {
      if (customMessage) {
        message.success(customMessage);
      } else {
        switch (method) {
          case HTTP_METHOD.POST:
            message.success('Successfully Created');
            break;
          case HTTP_METHOD.PUT:
            message.success('Successfully Updated');
            break;
          case HTTP_METHOD.DELETE:
            message.success('Successfully Deleted');
            break;
          default:
            break;
        }
      }
    }
    return responseData;
  } catch (error) {
    throw error;
  }
};
