import { Col, Row } from 'antd';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';

const mapBoxAccessToken = 'pk.eyJ1Ijoibmp2bW0iLCJhIjoiY2xuMTR6cjlqMDhhYTJqb2kwbmtudDdiciJ9.we-mhra0h3nMtaH1KU0WpQ';

class Testing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isDataFetching: true,
      lng: 0,
      lat: 0,
      zoom: 9,
      map: null,
    };
    this.mapContainer = React.createRef();
  }
  componentDidMount() {
    const { data } = this.state;
    if (data && data.locations) {
      mapboxgl.accessToken = mapBoxAccessToken;
      const map = new mapboxgl.Map({
        container: this.mapContainer,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [96.201387, 16.894444],
        zoom: 12,
      });
      map.on('load', () => {
        this.setState({ map });
        const firstLocation = data?.locations[0];
        map.setCenter([firstLocation.lng, firstLocation.lat]);
        this.setState({
          lat: firstLocation.lat,
          lng: firstLocation.lng,
        });
      });

      const route = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [
            [96.206339, 16.816519],
            [96.206181, 16.816404],
            [96.206237, 16.81632],
            [96.20622, 16.81629],
            [96.206144, 16.816405],
            [96.206024, 16.816576],
          ],
        },
      };

      map.on('load', function () {
        map.addSource('route', {
          type: 'geojson',
          data: route,
        });

        map.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          paint: {
            'line-color': 'black',
            'line-width': 3,
          },
        });
      });
    } else {
      mapboxgl.accessToken = mapBoxAccessToken;
      const map = new mapboxgl.Map({
        container: this.mapContainer,
        style: 'mapbox://styles/mapbox/streets-v11',
      });

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        defaultMode: 'draw_polygon',
      });
      map.addControl(draw);

      const lineString = turf.lineString([
        [7.646484375001052, 13.124285940332257],
        [10.250000429674003, 18.7246786798667],
        [22.696450891961945, 22.82411233310563],
        [30.874834733166338, 10.765648248518268],
        [16.611328125001478, 7.331821512982714],
        [7.646484375001052, 13.124285940332257],
      ]);
      draw.add(lineString);
      map.on('load', () => {
        this.setState({ map });
        map.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: [
                [96.201387, 16.894444],
                [96.199714, 16.893586],
                [96.199462, 16.893473],
                [96.197794, 16.892612],
                [96.198209, 16.891843],
                [96.198744, 16.890868],
                [96.198942, 16.890508],
                [96.199436, 16.88961],
                [96.199697, 16.889135],
                [96.19994, 16.888693],
                [96.200738, 16.887241],
                [96.20122, 16.886364],
                [96.201705, 16.885482],
                [96.201963, 16.885007],
                [96.200617, 16.884323],
                [96.200472, 16.884256],
                [96.20075, 16.883754],
                [96.201254, 16.882853],
                [96.201321, 16.882732],
                [96.201942, 16.88161],
                [96.202571, 16.880479],
                [96.202678, 16.880284],
                [96.202956, 16.879768],
                [96.203218, 16.879289],
                [96.203655, 16.878508],
                [96.2037, 16.878429],
                [96.203947, 16.877992],
                [96.204141, 16.877649],
                [96.204326, 16.877317],
                [96.204326, 16.87721],
                [96.204313, 16.877164],
                [96.204295, 16.87713],
                [96.204274, 16.877094],
                [96.204239, 16.877045],
                [96.203738, 16.876832],
                [96.203441, 16.876704],
                [96.203067, 16.876535],
                [96.202706, 16.876346],
                [96.201861, 16.875886],
                [96.201263, 16.875568],
                [96.200485, 16.87515],
                [96.200392, 16.875099],
                [96.198962, 16.874335],
                [96.19845, 16.874062],
                [96.198021, 16.873837],
                [96.197533, 16.873575],
                [96.196693, 16.873124],
                [96.196168, 16.872847],
                [96.195585, 16.87254],
                [96.194892, 16.872166],
                [96.194844, 16.87214],
                [96.194421, 16.871922],
                [96.194366, 16.871892],
                [96.193935, 16.871663],
                [96.193846, 16.871616],
                [96.193737, 16.871558],
                [96.193369, 16.871363],
                [96.19314, 16.871242],
                [96.192867, 16.871098],
                [96.192238, 16.870787],
                [96.191905, 16.870612],
                [96.191446, 16.870365],
                [96.191039, 16.870171],
                [96.190064, 16.869681],
                [96.188626, 16.868966],
                [96.187814, 16.868561],
                [96.1873, 16.868305],
                [96.186757, 16.86803],
                [96.186191, 16.86772],
                [96.185784, 16.867499],
                [96.184377, 16.866746],
                [96.183513, 16.866339],
                [96.183369, 16.866279],
                [96.182635, 16.865978],
                [96.182244, 16.865901],
                [96.182059, 16.86584],
                [96.181871, 16.865783],
                [96.181714, 16.865715],
                [96.181604, 16.865662],
                [96.181406, 16.865492],
                [96.180916, 16.865027],
                [96.180291, 16.864441],
                [96.178924, 16.863123],
                [96.178534, 16.862736],
                [96.178058, 16.862206],
                [96.177572, 16.861656],
                [96.177397, 16.861332],
                [96.177222, 16.861038],
                [96.176952, 16.86061],
                [96.176715, 16.860253],
                [96.176326, 16.859733],
                [96.176168, 16.85953],
                [96.176034, 16.859378],
                [96.175402, 16.858731],
                [96.175367, 16.858696],
                [96.175249, 16.858597],
                [96.175151, 16.858514],
                [96.174261, 16.85777],
                [96.174113, 16.857646],
                [96.173223, 16.856956],
                [96.172125, 16.856207],
                [96.171031, 16.855567],
                [96.170583, 16.855317],
                [96.170286, 16.855151],
                [96.169432, 16.854675],
                [96.169032, 16.85447],
                [96.168872, 16.854378],
                [96.168286, 16.854051],
                [96.16767, 16.853726],
                [96.167287, 16.853516],
                [96.167024, 16.853357],
                [96.166844, 16.853262],
                [96.166741, 16.853204],
                [96.166568, 16.853108],
                [96.166454, 16.853044],
                [96.1662, 16.852893],
                [96.164329, 16.851823],
                [96.164012, 16.851655],
                [96.163566, 16.851468],
                [96.163066, 16.851227],
                [96.162745, 16.851085],
                [96.162121, 16.85085],
                [96.16129, 16.850585],
                [96.16041, 16.850343],
                [96.160142, 16.850299],
                [96.159936, 16.850265],
                [96.159549, 16.850226],
                [96.159007, 16.850188],
                [96.157948, 16.850129],
                [96.157672, 16.85012],
                [96.157553, 16.850112],
                [96.157513, 16.849909],
                [96.157488, 16.84976],
                [96.157458, 16.849558],
                [96.157417, 16.849315],
                [96.157179, 16.84763],
                [96.157079, 16.846901],
                [96.156992, 16.846231],
                [96.156896, 16.845552],
                [96.156871, 16.845373],
                [96.156493, 16.842686],
                [96.156477, 16.842568],
                [96.156285, 16.841377],
                [96.156064, 16.839806],
                [96.155889, 16.838567],
                [96.155834, 16.83818],
                [96.155823, 16.838107],
                [96.15571, 16.837282],
                [96.155617, 16.836628],
                [96.155595, 16.836479],
                [96.15548, 16.835752],
                [96.155422, 16.835335],
                [96.155306, 16.834552],
                [96.155057, 16.832988],
                [96.154978, 16.832441],
                [96.154872, 16.831728],
                [96.154859, 16.831641],
                [96.154821, 16.831383],
                [96.154786, 16.831234],
                [96.154714, 16.830676],
                [96.154553, 16.829281],
                [96.154535, 16.829131],
                [96.154518, 16.828992],
              ],
            },
          },
        });

        map.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#888',
            'line-width': 4,
          },
        });
      });
    }
  }
  componentWillUnmount() {
    if (this.state.map) {
      this.state.map.remove();
    }
  }
  fly = () => {
    if (this.state.map) {
      this.state.map.flyTo({
        center: [this.state.lng, this.state.lat],
        essential: true,
      });
    }
  };
  fetchRoute = async (waypoints) => {
    try {
      const coordinates = waypoints.map((point) => point.join(',')).join(';');
      fetch(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${mapBoxAccessToken}`,
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          const route = data.routes[0].geometry;
          this.state.map.addLayer({
            id: uuidv4(),
            type: 'line',
            source: {
              type: 'geojson',
              data: {
                type: 'Feature',
                geometry: route,
              },
            },
            paint: {
              'line-width': 3,
              'line-color': 'black',
            },
          });
          const startCoordinates = data.waypoints[0].location;
          const endCoordinates = data.waypoints[data.waypoints.length - 1].location;

          const endMarkerElement = document.createElement('div');
          const endSvgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
          endSvgElement.setAttribute('width', '24');
          endSvgElement.setAttribute('height', '24');
          endSvgElement.innerHTML = `<path d="m19.071 17.213a10.141 10.141 0 0 0 0-14.259 9.94 9.94 0 0 0 -14.142 0 10.14 10.14 0 0 0 .026 14.284l3.584 3.339a4.92 4.92 0 0 0 6.9.018zm-5.729-2.564a3.023 3.023 0 0 1 -2.683 0l-2-1a2.986 2.986 0 0 1 -1.659-2.684v-2.965a2.986 2.986 0 0 1 1.658-2.685l2-1a3.014 3.014 0 0 1 2.683 0l2 1a2.986 2.986 0 0 1 1.659 2.685v2.966a2.986 2.986 0 0 1 -1.658 2.684zm1.607-6.955a1 1 0 0 1 .051.306v2.966a.994.994 0 0 1 -.553.895l-2 1a1.006 1.006 0 0 1 -.895 0l-2-1a.994.994 0 0 1 -.552-.896v-2.965a1 1 0 0 1 .051-.3l2.18 1.09a1.72 1.72 0 0 0 1.538 0zm5.051 15.306a1 1 0 0 1 -1 1h-14a1 1 0 0 1 0-2h14a1 1 0 0 1 1 1z" fill='red'/>`;
          endMarkerElement.appendChild(endSvgElement);

          const startMarkerElement = document.createElement('div');
          const startSvgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
          startSvgElement.setAttribute('width', '24');
          startSvgElement.setAttribute('height', '24');
          startSvgElement.innerHTML = `<path d="M11,1H3A3,3,0,0,0,0,4V14H14V4A3,3,0,0,0,11,1ZM4,11H2V4H4Zm4,0H6V4H8Zm2,0V4h2v7H10m6,0V5h3.559A3,3,0,0,1,22.4,7.051L23.721,11ZM0,18V16H16V13h8v5Zm.058,2c-.587,3.954,5.472,3.952,4.884,0Zm7,0c-.587,3.954,5.472,3.952,4.884,0Zm10,0c-.587,3.954,5.472,3.952,4.884,0Z"  fill='red'/>`;
          startMarkerElement.appendChild(startSvgElement);

          // new mapboxgl.Marker(startMarkerElement).setLngLat(startCoordinates).addTo(this.state.map);
          // new mapboxgl.Marker(endMarkerElement).setLngLat(endCoordinates).addTo(this.state.map);
        });
    } catch (error) {
      console.log(error);
    }
  };
  zoomIn() {
    const { map } = this.state;
    map.zoomIn();
  }
  zoomOut() {
    const { map } = this.state;
    map.zoomOut();
  }
  render() {
    return (
      <>
        <Row>
          <Col span={1} />
          <Col span={22}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div
                  ref={(el) => (this.mapContainer = el)}
                  style={{ width: '100%', height: '100vh', borderRadius: 5 }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default Testing;
