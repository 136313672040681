import { Avatar, Breadcrumb, Button, Card, Col, Descriptions, Drawer, List, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SvgChatHistoryFilled } from '../../component/custom-svg-icon';
import Theme from '../../component/theme';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const { Text } = Typography;

const TripHistoryDetail = () => {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState();

  useEffect(() => {
    findTripHistory();
  }, []);

  const findTripHistory = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      tripId: filterData?.searchTripId?.trim() ? filterData?.searchTripId?.trim() : '',
      licenseNumber: filterData?.searchLicenseNumber?.trim() ? filterData?.searchLicenseNumber?.trim() : '',
      name: filterData?.searchName?.trim() ? filterData?.searchName?.trim() : '',
      phoneNumber: filterData?.searchPhoneNumber?.trim() ? filterData?.searchPhoneNumber?.trim() : '',
    };
    ApiHandler({
      url: Api.trip_history,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        setData(response);
      })
      .catch(() => setLoading(false));
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const items = [
    {
      key: '1',
      label: 'Trip ID ',
      children: data.tripNumber || '-',
    },
    {
      key: '2',
      label: 'Trip Create Date/Time',
      children: data.createdDate || '-',
    },
    {
      key: '3',
      label: 'Driver Accepted Date/Time',
      children: data.driverAcceptedDate || '-',
    },
    {
      key: '4',
      label: 'Driver Arrival Date/Time',
      children: data.driverArrivalDate || '-',
    },
    {
      key: '5',
      label: 'Pickup Location',
      children: data?.tripPickUpLocation?.address || '-',
    },
    {
      key: '6',
      label: 'Drop-off Location',
      children: data?.tripDropOffLocation?.address || '-',
    },
    {
      key: '7',
      label: 'Distance to Pickup Location',
      children: data.distanceToPickupLocation || '-',
    },
    {
      key: '8',
      label: 'Distance Traveled',
      children: data.distanceTraveled || '-',
    },
    {
      key: '9',
      label: 'Fare Breakdown',
      children: data.fareBreakdown || '-',
    },
    {
      key: '10',
      label: 'Payment Method',
      children: data.paymentMethod || '-',
    },
    {
      key: '11',
      label: 'Driver Name',
      children: data.dirverName || '-',
    },
    {
      key: '12',
      label: 'Vehicle License Plate Number',
      children: data.vehicleLicensePlateNumber || '-',
    },
    {
      key: '13',
      label: 'Passenger Information',
      children: data.passengerInformation || '-',
    },
    {
      key: '14',
      label: 'Trip Status',
      children: data.tripStatus || '-',
    },
    {
      key: '15',
      label: 'Route Map',
      children: data.routeMap || '-',
    },
    {
      key: '16',
      label: 'Rating & Feedback',
      children: data.ratingAndFeedback || '-',
    },
    {
      key: '17',
      label: (
        <div>
          <div>Chat History</div>
          <Button
            style={{ marginTop: '10px', height: 50, width: 50 }}
            icon={<SvgChatHistoryFilled color="white" width="22px" height="22px" />}
            type="primary"
            tooltip={<div>View Chat History</div>}
            onClick={showDrawer}
          />
        </div>
      ),
    },
  ];

  const chatData = [
    {
      sender: 'Alice',
      message: 'Hello, how are you?',
      time: '10:00 AM',
    },
    {
      sender: 'Bob',
      message: 'I am good, thanks! How about you?',
      time: '10:05 AM',
    },
    {
      sender: 'Alice',
      message: 'Doing well, thanks for asking.',
      time: '10:07 AM',
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Drawer title="Chat History" placement="right" onClose={onClose} visible={visible} width={400}>
        <List
          itemLayout="horizontal"
          dataSource={chatData}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar>{item.sender[0]}</Avatar>}
                title={<Text strong>{item.sender}</Text>}
                description={
                  <>
                    <Text>{item.message}</Text>
                    <br />
                    <Text type="secondary">{item.time}</Text>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Drawer>
      <Col span={24}>
        <Breadcrumb
          items={[
            {
              title: 'Home',
            },
            {
              title: <Link to={CustomPath.trip_history}>Trip History</Link>,
            },
            {
              title: 'Trip History Detail',
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <Card
          style={{
            backgroundColor: Theme.colors.card_bg_color,
            boxShadow: 'none',
            borderRadius: '8px',
            padding: '20px',
          }}
          bordered={false}
          title={'Trip History Detail'}
          extra={
            <Link to={CustomPath.trip_history}>
              <Button type="primary">Back</Button>
            </Link>
          }
        >
          <Descriptions
            colon={false}
            items={items}
            column={1}
            size="large"
            layout="horizontal"
            labelStyle={{
              fontSize: '18px',
              color: '#333232',
              fontWeight: 'bold',
              width: '45%',
              textAlign: 'left',
            }}
            contentStyle={{
              fontSize: '18px',
              color: '#333',
              width: '50%',
              textAlign: 'left',
              marginLeft: '0px',
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TripHistoryDetail;
