import { Button, Checkbox, Col, Form, message, Modal, Row, Skeleton, Space, Tag, TimePicker, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { NJVAddButton, NJVTable, TitleLevel3 } from '../../component/core-component';
import { Constant, DayOfWeek } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import { EditableCell } from './distance-fare';
import { useToggle } from 'react-use';
import { ValidateFromTimeToTime } from '../../core/validator';

const PeakHour = () => {
  const pageSize = Constant.pageSize;
  const type = '';

  const [page, setPage] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [count, setCount] = useState(0);
  const [newRowKey, setNewRowKey] = useState(null);
  const [submitting, toggleSubmitting] = useToggle(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [isModelOpen, toggleModelOpen] = useToggle(false);
  const [selectedId, setSelectedId] = useState();

  const fetchData = useCallback(
    (pageNumber) => {
      const requestParams = {
        size: pageSize,
        page: pageNumber ? pageNumber - 1 : page,
      };
      ApiHandler({
        url: Api.peak_hour_setup_filter,
        method: HTTP_METHOD.GET,
        MediaType: MEDIA_TYPE.JSON,
        requestParams,
      })
        .then((response) => {
          setData(
            response.map((d) => ({
              ...d,
              fromTime: dayjs(d.fromTime),
              toTime: dayjs(d.toTime),
              key: d.id,
            })),
          );
          setSelectedDays(response.daysOfWeek);
        })
        .catch()
        .finally(() => setDataFetching(false));
    },
    [page, pageSize],
  );

  useEffect(() => {
    setDataFetching(true);
    fetchData();
  }, [fetchData]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue(record);
    setSelectedDays(record.daysOfWeek);
    setEditingKey(record.key);
    setNewRowKey(false);
  };

  const cancel = (key) => {
    if (newRowKey) {
      const newData = data.filter((item) => item.key !== key);
      setData(newData);
      setEditingKey('');
    } else {
      setEditingKey('');
    }
  };

  const handleCreate = () => {
    const newKey = count.toString();
    const newRow = {
      key: newKey,
    };
    form.setFieldsValue({ key: newRow.key, fromTime: '', toTime: '', price: '' });
    setSelectedDays(Object.keys(DayOfWeek));
    setData([...data, newRow]);
    setCount(count + 1);
    setEditingKey(newKey);
    setNewRowKey(true);
  };

  const EditSave = (row) => {
    const newData = [...data];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setData(newData);
  };

  const save = async (data) => {
    const requestData = form.getFieldsValue();
    toggleSubmitting();
    ApiHandler({
      url: Api.peak_hour_setup,
      method: data.id ? HTTP_METHOD.PUT : HTTP_METHOD.POST,
      MediaType: MEDIA_TYPE.JSON,
      requestData: { ...data, ...requestData, daysOfWeek: selectedDays },
      disableShowMessage: false,
    })
      .then(() => {
        fetchData();
        setEditingKey('');
        form.setFieldsValue({ key: data.key, fromTime: '', toTime: '', price: '', daysOfWeek: [] });
        setSelectedDays([]);
      })
      .catch(() => {})
      .finally(toggleSubmitting);
  };

  const defaultColumns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Time Range',
      dataIndex: 'timeRange',
      key: 'timeRange',
      width: '30%',
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Space direction="horizontal">
              <Form.Item
                name="fromTime"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'From Time is required',
                  },
                  { validator: ValidateFromTimeToTime(form, data, record.key) },
                ]}
              >
                <TimePicker defaultValue={record.fromTime} format="hh:mm A" use12Hours style={{ marginRight: 8 }} />
              </Form.Item>
              <Form.Item
                name="toTime"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'To Time is required',
                  },
                  { validator: ValidateFromTimeToTime(form, data, record.key) },
                ]}
              >
                <TimePicker defaultValue={record.toTime} format="hh:mm A" use12Hours />
              </Form.Item>
            </Space>
          </>
        ) : (
          <>
            <span style={{ marginRight: 10 }}>From</span>
            {record.fromTime?.format('hh:mm A')}
            <span style={{ margin: '0px 10px' }}>To</span>
            {record.toTime?.format('hh:mm A')}
          </>
        );
      },
    },
    {
      title: 'Charges',
      dataIndex: 'price',
      key: 'price',
      editable: true,
      render: (charge) => (
        <>
          {charge}
          <span style={{ marginLeft: '4px' }}>MMK</span>
        </>
      ),
    },
    {
      title: '',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Checkbox
            checked={selectedDays?.length === 7}
            onChange={(e) => setSelectedDays(e.target.checked ? Object.keys(DayOfWeek) : [])}
          >
            All Days
          </Checkbox>
        ) : (
          ''
        );
      },
    },
    {
      title: 'Days Of Week',
      dataIndex: 'daysOfWeek',
      key: 'daysOfWeek',
      render: (text, record) => {
        const editable = isEditing(record);

        const onClickDaysOfWeek = (day) => {
          const currentDaysOfWeek = selectedDays;
          const isDaySelected = currentDaysOfWeek.includes(day);
          const updatedDaysOfWeek = isDaySelected
            ? currentDaysOfWeek.filter((d) => d !== day)
            : [...currentDaysOfWeek, day];
          setSelectedDays(updatedDaysOfWeek);
        };
        return editable ? (
          <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            {Object.keys(DayOfWeek).map((day) => {
              return (
                <Button
                  key={day}
                  type={selectedDays.includes(day) ? 'primary' : 'default'}
                  shape="circle"
                  onClick={() => onClickDaysOfWeek(day)}
                >
                  {day[0]}
                </Button>
              );
            })}
          </div>
        ) : (
          <>
            {record.daysOfWeek?.map((day) => (
              <Tag key={day}>{DayOfWeek[day]}</Tag>
            ))}
          </>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button type="primary" style={{ marginRight: 8 }} loading={submitting} onClick={() => save(record)}>
              Save
            </Button>
            <Button title="Sure to cancel?" onClick={() => cancel(record.key)}>
              Cancel
            </Button>
          </span>
        ) : (
          <span>
            <Button type="primary" disabled={editingKey !== ''} onClick={() => edit(record)} style={{ marginRight: 8 }}>
              Edit
            </Button>
            <Button
              size={'middle'}
              type="primary"
              danger
              onClick={() => {
                setSelectedId(record.id);
                toggleModelOpen();
              }}
            >
              Delete
            </Button>
          </span>
        );
      },
    },
  ];

  const deleteRow = (id) => {
    ApiHandler({
      url: Api.peak_hour_delete,
      method: HTTP_METHOD.POST,
      requestData: {
        id,
      },
    }).finally(() => {
      toggleModelOpen(false);
      fetchData();
    });
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        EditSave,
      }),
    };
  });

  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Peak Hour'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <NJVAddButton type={type} handleClick={handleCreate} />
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <Form form={form} component={false}>
              <NJVTable
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="editable-row"
                pagination={false}
              />
            </Form>
          )}
        </Col>
      </Row>
      <Modal
        title="Delete PeakHour Rule"
        open={isModelOpen}
        onOk={() => deleteRow(selectedId)}
        okType="danger"
        onCancel={toggleModelOpen}
      >
        <p>Are you sure want to delete?</p>
      </Modal>
    </>
  );
};
export default PeakHour;
