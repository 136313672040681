import { Breadcrumb, Button, Card, Col, Form, message, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { NJVButton, NJVDatePicker, NJVInput, NJVSelect } from '../../component/core-component';
import Theme from '../../component/theme';
import CustomPath from '../../route/custom-path';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { PromotionType } from '../../core/constant';
import dayjs from 'dayjs';

function PromotionUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const fromRef = useRef();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    findPromotion(id);
  }, []);

  const findPromotion = async () => {
    await ApiHandler({
      url: Api.promotion,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        response = {
          ...response,
          startDate: dayjs(response.startDate, 'YYYY-MM-DD'),
          endDate: dayjs(response.endDate, 'YYYY-MM-DD'),
        };
        setData(response);
        fromRef.current.setFieldsValue(response);
      })
      .catch((error) => setLoading(false));
  };

  const updatePromotion = (formData) => {
    if (formData.type === PromotionType.Percentage && formData.amount > 100) {
      message.error('The promotion percentage cannot exceed 100%');
      return;
    }
    setLoading(true);

    ApiHandler({
      url: Api.promotion,
      method: HTTP_METHOD.PUT,
      requestData: {
        ...formData,
        id: data.id,
        startDate: formData.startDate.format('YYYY-MM-DD'),
        endDate: formData.endDate.format('YYYY-MM-DD'),
      },
    })
      .then((response) => {
        backToHome();
      })
      .catch((error) => setLoading(false));
  };

  const backToHome = () => navigate(CustomPath.promotion);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.promition}>Promotion</Link>,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'Promotion Form'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form layout="vertical" onFinish={updatePromotion} ref={fromRef}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="promotionCode"
                    label={'Promotion Code'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput disabled placeholder={'Enter Promotion Code'} style={{ color: '#333' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="type" label={'Promotion Type'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVSelect>
                      {Object.entries(PromotionType).map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="startDate"
                    label={'Start Date Time'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVDatePicker size="large" style={{ width: '100%' }} placeholder={'Select Start Date'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="endDate" label={'End Date'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVDatePicker size="large" style={{ width: '100%' }} placeholder={'Select End Date'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="amount" label={'Amount'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter Amount'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="limitPerUser"
                    label={'Limit Per User'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput placeholder={'Enter Limit Per User'} type="number" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="description" label={'Description'}>
                    <NJVInput
                      istextareainput={true}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder={'Enter Description'}
                    />
                  </Form.Item>
                </Col>
                <Form.Item>
                  <NJVButton type="primary" htmlType="submit" loading={loading}>
                    Update
                  </NJVButton>
                </Form.Item>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default PromotionUpdate;
