import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Col, Row } from 'antd';
const mapBoxAccessToken = 'pk.eyJ1Ijoibmp2bW0iLCJhIjoiY2xuMTR6cjlqMDhhYTJqb2kwbmtudDdiciJ9.we-mhra0h3nMtaH1KU0WpQ';

class Testing2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isDataFetching: true,
      lng: 0,
      lat: 0,
      zoom: 9,
      map: null,
      draw: null,
      roundedArea: null,
    };
    this.mapContainer = React.createRef();
  }
  componentDidMount() {
    mapboxgl.accessToken = mapBoxAccessToken;
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 14,
    });
    this.map.on('click', this.onMapClick);
    this.map.on('load', () => {
      this.map.setCenter([96.08616299784325, 16.82649501109621]);

      const polygon = turf.polygon([
        [
          [96.07860989726049, 16.830424012611843],
          [96.09148450053135, 16.829191695497542],
          [96.08938164866345, 16.825166070390267],
          [96.08311600840443, 16.82257812336684],
          [96.07955403483328, 16.82590547733315],
          [96.07860989726049, 16.830424012611843],
        ],
      ]);
      // draw polygon and editable
      // draw.add(polygon);
      const center = turf.center(polygon);
      this.map.addSource('polygon', {
        type: 'geojson',
        data: polygon,
      });

      this.map.addLayer({
        id: 'polygon',
        type: 'fill',
        source: 'polygon',
        layout: {},
        paint: {
          'fill-color': 'red',
          'fill-opacity': 0.7,
        },
      });

      this.map.addSource('polygon-text', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: center.geometry,
              properties: {
                text: 'Zone1',
              },
            },
          ],
        },
      });
      this.map.addLayer({
        id: 'polygon-text',
        type: 'symbol',
        source: 'polygon-text',
        layout: {
          'text-field': ['get', 'text'],
          'text-size': 13,
          'text-offset': [0, 0],
          'text-anchor': 'bottom',
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
        },
        paint: {
          'text-color': '#fff',
        },
      });
    });

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      defaultMode: 'simple_select',
      // defaultMode: 'draw_polygon',
    });

    this.map.addControl(draw);
    this.map.on('draw.create', (e) => this.updateArea(e, draw));
    this.map.on('draw.delete', (e) => this.updateArea(e, draw));
    this.map.on('draw.update', (e) => this.updateArea(e, draw));
  }

  onMapClick = (event) => {
    const { lng, lat } = event.lngLat;
    const el = document.createElement('div');
    el.className = 'custom-marker';
    el.style.backgroundImage = `url(/images/station_marker.png)`;
    el.style.width = '50px';
    el.style.height = '50px';
    el.style.marginTop = '-30px';

    el.style.backgroundSize = '100%';
    new mapboxgl.Marker({ element: el, anchor: 'center', draggable: true }).setLngLat([lng, lat]).addTo(this.map);
  };

  updateArea = (e, draw) => {
    const data = draw.getAll();
    console.log(data);
    if (data.features.length > 0) {
      const area = turf.area(data);
      console.log(data);
      console.log(data.features[0].geometry.coordinates);
      this.setState({ roundedArea: Math.round(area * 100) / 100 });
    }
  };

  render() {
    return (
      <>
        <Row>
          <Col span={24}>
            <div
              ref={(el) => (this.mapContainer = el)}
              style={{ width: '100%', height: '90vh', borderRadius: 5 }}
            ></div>
          </Col>
        </Row>
      </>
    );
  }
}

export default Testing2;
