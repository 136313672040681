import { Breadcrumb, Button, Card, Checkbox, Col, Form, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { NJVButton, NJVInput, NJVSelect } from '../../component/core-component';
import Theme from '../../component/theme';
import CustomPath from '../../route/custom-path';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { Constant, sosStatus } from '../../core/constant';

function SosAlertUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [passengers, setPassengers] = useState([]);
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    findSosAlert(id);
  }, []);

  const findSosAlert = async () => {
    await ApiHandler({
      url: Api.sos,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        setData(response);
        formRef.current.setFieldsValue(response);
      })
      .catch((error) => setLoading(false));
  };

  const updateSos = (formData) => {
    setLoading(true);

    ApiHandler({
      url: Api.sos,
      method: HTTP_METHOD.PUT,
      requestData: { ...formData, id: data.id },
    })
      .then((response) => {
        backToHome();
      })
      .catch((error) => setLoading(false));
  };

  useEffect(() => {
    const findAllPassenger = () => {
      ApiHandler({
        url: Api.passenger_filter,
        method: HTTP_METHOD.GET,
      }).then((response) => {
        const content = response.content;
        setPassengers(content);
      });
    };
    const findAllDriver = () => {
      ApiHandler({
        url: Api.driver_filter,
        method: HTTP_METHOD.GET,
      }).then((response) => {
        const content = response.content;
        setDrivers(content);
      });
    };
    findAllPassenger();
    findAllDriver();
  }, []);

  const backToHome = () => navigate(CustomPath.sos);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.sos}>Sos Alerts</Link>,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'SOS Form'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form layout="vertical" ref={formRef} onFinish={updateSos}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item name="sosStatus" label={'SSO Status'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVSelect>
                      {Object.entries(sosStatus).map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="notes" label={'Notes'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput
                      istextareainput={true}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder={'Enter Notes'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="passengerId"
                    label={'Passenger'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVSelect>
                      {passengers.map(({ id, fullName }) => (
                        <Select.Option key={id} value={id}>
                          {fullName}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="driverId" label={'Driver'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVSelect>
                      {drivers.map(({ id, fullName }) => (
                        <Select.Option key={id} value={id}>
                          {fullName}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="latitude" label={'Latitude'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter Latitiude'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="longitude" label={'Longitude'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter Longitude'} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <NJVButton type="primary" htmlType="submit" loading={loading}>
                      Update
                    </NJVButton>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default SosAlertUpdate;
