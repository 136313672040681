import { Col, Descriptions, message, Modal, Row, Skeleton, Space, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import {
  getRowClassName,
  NJVButton,
  NJVInput,
  NJVRangePicker,
  NJVTable,
  TitleLevel3,
} from '../../component/core-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';
import PromotionHistoryDetail from './promotion-history-detail';

const PromotionHistory = () => {
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);
  const [tripId, setTripId] = useState();
  const [isModelOpen, toggleModelOpen] = useToggle(false);

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 55,
      fixed: 'left',
    },
    {
      title: 'Trip ID',
      dataIndex: 'trip_id',
      fixed: 'left',
    },
    {
      title: 'Driver Name',
      dataIndex: 'driverName',
    },
    {
      title: 'Vehicle License Plate Number',
      dataIndex: 'licensePlateNumber',
    },
    {
      title: 'Passenger Name',
      dataIndex: 'passengerName',
    },
    {
      title: 'Passenger Phone Number',
      dataIndex: 'passengerPhoneNumber',
    },
    {
      title: 'Trip Fees',
      dataIndex: 'tripFees',
    },
    {
      title: 'Discount Code',
      dataIndex: 'discountCode',
    },
    {
      title: 'View Details',
      textAlign: 'view_details',
      render: (history) => (
        <Space>
          <a onClick={() => toggleModelOpen(true)}>View Detail</a>
        </Space>
      ),
      fixed: 'right',
    },
  ];

  const fetchData = useCallback(
    (pageNumber) => {
      const requestParams = {
        size: pageSize,
        page: pageNumber ? pageNumber - 1 : page,
      };
      ApiHandler({
        url: Api.promotion_history,
        method: HTTP_METHOD.GET,
        MediaType: MEDIA_TYPE.JSON,
        requestParams,
      })
        .then((response) => {
          const content = response.content;
          setPage(pageNumber ? pageNumber - 1 : page);
          setTotalPagination(response?.totalElements);
          setData(content);
        })
        .catch((error) => {
          message.error(error);
        })
        .finally(() => toggleDataFetching(false));
    },
    [page, pageSize, toggleDataFetching],
  );

  const handleSearch = () => {
    fetchData({ tripId, dateAndTime: '' });
  };

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  return (
    <>
      <Modal
        title={'Promotion History Detail'}
        open={isModelOpen}
        footer={false}
        onCancel={toggleModelOpen}
        width={800}
      >
        <PromotionHistoryDetail />
      </Modal>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Promotion History'} />
        </Col>
        <Col span={6}>
          <NJVInput bgcolor="#f1f1f1" placeholder="Search by DriverName" />
        </Col>
        <Col span={6}>
          <NJVInput bgcolor="#f1f1f1" placeholder="Search by Vehicle License Plate Number" />
        </Col>
        <Col span={6}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={6}></Col>
        <Col span={6}>
          <NJVInput bgcolor="#f1f1f1" placeholder="Search by PassengerName" />
        </Col>
        <Col span={6}>
          <NJVInput bgcolor="#f1f1f1" placeholder="Search by PassengerPhoneNumber" />
        </Col>
        <Col span={24}>
          {isFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={columns}
              dataSource={data}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default PromotionHistory;
