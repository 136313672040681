import {
  SvgAdminFilled,
  SvgAssignFilled,
  SvgAvailabilityCheck,
  SvgBookingFilled,
  SvgDistanceChargeFilled,
  SvgDriverFilled,
  SvgFAQFilled,
  SvgHistoryFilled,
  SvgNotificationChannelFilled,
  SvgPassengerFilled,
  SvgPeakHourFilled,
  SvgPickupFilled,
  SvgPointFilled,
  SvgPromoFilled,
  SvgPushFilled,
  SvgRegionFilled,
  SvgRoleFilled,
  SvgSosFilled,
  SvgStationFilled,
  SvgTimeFareFilled,
  SvgVehicleFilled,
  SvgWaitingChargeFilled,
  SvgWeatherFilled,
  SvgZoneFilled,
} from '../component/custom-svg-icon';

export const unselectColor = '#989898';

export const GenerateSvgIconBaseOnName = (name, selectedIndex, currentIndex) => {
  let svgIcon;
  const isSelect = selectedIndex === currentIndex ? true : false;
  if (name === 'Admin') {
    svgIcon = <SvgAdminFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Role') {
    svgIcon = <SvgRoleFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Driver') {
    svgIcon = <SvgDriverFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Vehicle') {
    svgIcon = <SvgVehicleFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Passenger') {
    svgIcon = <SvgPassengerFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Assign') {
    svgIcon = <SvgAssignFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Region') {
    svgIcon = <SvgRegionFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Booking') {
    svgIcon = <SvgBookingFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Pickup Fare') {
    svgIcon = <SvgPickupFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Waiting Charges') {
    svgIcon = <SvgWaitingChargeFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Distance Fare') {
    svgIcon = <SvgDistanceChargeFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Time Fare') {
    svgIcon = <SvgTimeFareFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Traffic Charges') {
    svgIcon = <SvgTimeFareFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Weather Condition') {
    svgIcon = <SvgTimeFareFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Peak Hour') {
    svgIcon = <SvgPeakHourFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'FAQ') {
    svgIcon = <SvgFAQFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Notification Channel') {
    svgIcon = <SvgNotificationChannelFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Charging Station') {
    svgIcon = <SvgStationFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Zone') {
    svgIcon = <SvgZoneFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Zone Pricing') {
    svgIcon = <SvgZoneFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Availability Check') {
    svgIcon = <SvgAvailabilityCheck width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'SOS Alerts') {
    svgIcon = <SvgSosFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Driver Points Management') {
    svgIcon = <SvgPointFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Send Notification') {
    svgIcon = <SvgPushFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Notification History') {
    svgIcon = <SvgHistoryFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Trip History') {
    svgIcon = <SvgHistoryFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Promotion') {
    svgIcon = <SvgPromoFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  } else if (name === 'Weather Charges') {
    svgIcon = <SvgWeatherFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />;
  }

  return svgIcon;
};

export const GetListFromEnumObject = (enumlist) => {
  const temp = enumlist.map((res, index) => {
    return {
      key: index + 1,
      value: res.key,
      label: res.value,
    };
  });
  return temp;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
