const prefix = 'api/v1/';
const Api = {
  host: 'https://devadminapi.zeimm.com',
  // host: 'http://localhost:9093',

  enum_filter: prefix + 'enum/filter',

  user_update_device_token: prefix + 'user/update-device-token',

  admin: prefix + 'admin',
  admin_filter: prefix + 'admin/filter',

  role: prefix + 'role',
  role_filter: prefix + 'role/filter',
  role_menus: prefix + 'enum/filter?enumType=MENU',

  driver: prefix + 'driver',
  driver_filter: prefix + 'driver/filter',
  driverAssign: prefix + 'driver/assign',
  drivers_all: prefix + 'driver/find-all',
  update_driver_points: prefix + 'driver/update-points',
  driver_profile_upload: prefix + 'driver/upload',
  driver_get_unassigned_list_by_date: prefix + 'driver/get-unassigned-drivers-by-date',
  driver_get_assigned_list_by_date: prefix + 'driver/get-assigned-drivers-by-date',

  passenger: prefix + 'passenger',
  passenger_filter: prefix + 'passenger/filter',

  trip_history: prefix + 'trip',
  trip_history_filter: prefix + 'trip/filter',

  vehicle: prefix + 'vehicle',
  vehicle_filter: prefix + 'vehicle/filter',
  vehicle_get_unassigned_list_by_date: prefix + 'vehicle/get-unassigned-vehicles-by-date',
  vehicle_get_assigned_list_by_date: prefix + 'vehicle/get-assigned-vehicles-by-date',

  region: prefix + 'region',
  region_filter: prefix + 'region/filter',

  zone: prefix + 'zone',
  zone_filter: prefix + 'zone/filter',
  zone_find_by_region: prefix + 'zone/find-zones-by-region',
  zone_pricing: prefix + 'zone-pricing',
  zone_pricing_filter: prefix + 'zone-pricing/filter',

  faq: prefix + 'faq',
  faq_filter: prefix + 'faq/filter',
  faq_all: prefix + 'faq/find-all',
  delete_faq: prefix + 'faq/delete',

  promotion: prefix + 'promotion',
  promotion_filter: prefix + 'promotion/filter',
  promotion_all: prefix + 'promotion/find-all',
  delete_promotion: prefix + 'promotion/delete',

  sos: prefix + 'sos',
  sos_filter: prefix + 'sos/filter',

  charging_station: prefix + 'charging-station',
  charging_station_filter: prefix + 'charging-station/filter',
  charging_station_all: prefix + 'charging-station/find-all',

  notification_channel: prefix + 'notification_channel',
  notification_channel_filter: prefix + 'notification_channel/filter',
  notification_history_filter: prefix + 'notification_history/filter',
  notification_push_by_topic: prefix + 'notification/topic',

  reset_multiple_points: prefix + 'driver/reset-multiple-points',

  login: prefix + 'public/admin/login',

  refresh_token: prefix + 'public/refresh-token',

  base_fare_rule: prefix + 'base-fare-rule',
  base_fare_rule_delete: prefix + 'base-fare-rule/delete',
  base_fare_rule_filter: prefix + 'base-fare-rule/filter',

  fare_rule: prefix + 'fare-rule',
  fare_rule_delete: prefix + 'fare-rule/delete',
  fare_rule_filter: prefix + 'fare-rule/filter',

  peak_hour_setup: prefix + 'peak-hour-rule',
  peak_hour_delete: prefix + 'peak-hour-rule/delete',
  peak_hour_setup_filter: prefix + 'peak-hour-rule/filter',

  weather_condition_setup: prefix + 'weather-charges-rule',
  weather_condition_delete: prefix + 'weather-charges-rule/delete',
  weather_condition_setup_filter: prefix + 'weather-charges-rule/filter',
};

export default Api;
