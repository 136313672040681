import { Breadcrumb, Button, Card, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Theme from '../../../component/theme';
import Api from '../../../network/api';
import { HTTP_METHOD } from '../../../network/httpMethod';
import { MEDIA_TYPE } from '../../../network/mediaType';
import { ApiHandler } from '../../../network/network-manager';
import CustomPath from '../../../route/custom-path';
import { NJVInput } from '../../../component/core-component';

function RoleUpdate() {
  const formRef = useRef();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [menuList, setMenuList] = useState([]);

  const [menuData, setMenuData] = useState([]);
  const { id } = useParams();

  const fetchMenuList = () => {
    ApiHandler({
      url: Api.role_menus,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    })
      .then((response) => {
        setMenuList(response);
      })
      .catch(() => {});
  };

  const fetchData = () => {
    ApiHandler({
      url: Api.role,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        setData(response);
        const updatedValues = {
          name: response.name,
          description: response.description,
          roleMenus: menuList.map((item) => {
            const matchedRoleMenu = response.roleMenus.find((roleMenu) => roleMenu.menu === item.key);
            return {
              id: matchedRoleMenu ? matchedRoleMenu.id : null,
              menu: item.key,
              read: matchedRoleMenu ? matchedRoleMenu.read : false,
              write: matchedRoleMenu ? matchedRoleMenu.write : false,
              delete: matchedRoleMenu ? matchedRoleMenu.delete : false,
            };
          }),
        };
        formRef.current.setFieldsValue(updatedValues);
      })
      .catch(() => {})
      .finally(() => setDataFetching(false));
  };

  useEffect(() => {
    fetchMenuList();
  }, []);

  useEffect(() => {
    fetchData();
  }, [menuList]);

  useEffect(() => {
    const updatedMenuData = menuList.map((item) => ({
      menu: item.key,
      read: false,
      write: false,
      delete: false,
    }));
    setMenuData(updatedMenuData);
  }, [menuList]);

  const updateRole = (values) => {
    setLoading(true);
    const roleMenus = menuList.map((item, index) => {
      const matchingMenu = data.roleMenus.find((menu) => menu.menu === item.key);

      return {
        [`roleMenus[${index}].id`]: matchingMenu ? matchingMenu.id : null,
        [`roleMenus[${index}].menu`]: item.key,
        [`roleMenus[${index}].read`]: values.roleMenus?.[index]?.read || false,
        [`roleMenus[${index}].write`]: values.roleMenus?.[index]?.write || false,
        [`roleMenus[${index}].delete`]: values.roleMenus?.[index]?.delete || false,
      };
    });

    const formData = {
      id: data.id,
      name: values.name,
      description: values.description,
      ...roleMenus.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    };
    const requestData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== undefined && formData[key] !== null) {
        requestData.append(key, formData[key]);
      }
    });

    ApiHandler({
      url: Api.role,
      method: HTTP_METHOD.PUT,
      mediaType: MEDIA_TYPE.FORM_DATA,
      requestData,
    })
      .then((response) => {
        backToHome();
      })
      .catch((error) => setLoading(false));
  };

  const backToHome = () => navigate(CustomPath.role);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.role}>Role</Link>,
              },
              {
                title: 'Update Role',
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'Update Role'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form ref={formRef} layout="vertical" initialValues={{ active: true }} onFinish={updateRole}>
              <Row gutter={[10]}>
                <Col span={24}>
                  <Form.Item name="name" label={'Name'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="description" label={'Description'}>
                    <NJVInput placeholder="Description" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Card style={{ marginBottom: 20 }}>
                    <Row>
                      <Col span={12} style={{ fontWeight: 'bold' }}>
                        Menu
                      </Col>
                      <Col span={4} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Read
                      </Col>
                      <Col span={4} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Write/Edit
                      </Col>
                      <Col span={4} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Delete
                      </Col>
                    </Row>
                  </Card>
                </Col>

                {menuList.map((item, index) => (
                  <Col span={24} key={item.key} style={{ marginBottom: 10 }}>
                    <Row align="middle">
                      <Col span={12} style={{ display: 'flex', alignItems: 'center', marginLeft: 25 }}>
                        {item.value}
                      </Col>

                      <Col span={3} style={{ textAlign: 'center' }}>
                        <Form.Item name={['roleMenus', index, 'read']} valuePropName="checked" initialValue={false}>
                          <Checkbox />
                        </Form.Item>
                      </Col>

                      <Col span={4} style={{ textAlign: 'center', marginLeft: 15 }}>
                        <Form.Item name={['roleMenus', index, 'write']} valuePropName="checked" initialValue={false}>
                          <Checkbox />
                        </Form.Item>
                      </Col>

                      <Col span={4} style={{ textAlign: 'center' }}>
                        <Form.Item name={['roleMenus', index, 'delete']} valuePropName="checked" initialValue={false}>
                          <Checkbox />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>

              <Row justify="end" style={{ marginTop: 20 }}>
                <Col>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RoleUpdate;
