import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Select, Skeleton, Space } from 'antd';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVEditButton,
  NJVInput,
  NJVSelect,
  NJVTable,
  TitleLevel3,
} from '../../component/core-component';
import { Constant, PromotionType } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';
import { MEDIA_TYPE } from '../../network/mediaType';

const PromotionPage = () => {
  const pageSize = Constant.pageSize;
  const type = '';
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);
  const [selectedId, setSelectedId] = useState();
  const [isModelOpen, toggleModelOpen] = useToggle(false);
  const [filterData, setFilterData] = useState();

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      promoCode: filterData?.searchPromoCode?.trim() ? filterData?.searchPromoCode?.trim() : '',
      promoType: filterData?.searchPromoType?.trim() ? filterData?.searchPromoType?.trim() : '',
    };

    ApiHandler({
      url: Api.promotion_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => toggleDataFetching(false));
  };

  const handleSearch = () => {
    fetchData();
  };

  const deleteRow = (id) => {
    ApiHandler({
      url: Api.delete_promotion,
      method: HTTP_METHOD.POST,
      requestData: {
        id,
      },
    }).finally(() => {
      toggleModelOpen(false);
      fetchData();
    });
  };

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    toggleDataFetching();
    fetchData();
  }, []);

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Promotion Code',
      dataIndex: 'promotionCode',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Limit Per User',
      dataIndex: 'limitPerUser',
    },
    {
      title: 'Promotion Type',
      dataIndex: 'type',
      render: (type) => <span>{PromotionType[type]}</span>,
    },
    {
      title: 'Start Date Time',
      dataIndex: 'startDate',
      render: (dt) => <span>{dt}</span>,
    },
    {
      title: 'End Date Time',
      dataIndex: 'endDate',
      render: (dt) => <span>{dt}</span>,
    },
    {
      title: 'Action',
      textAlign: 'center',
      render: (row) => (
        <Space>
          <Link to={`${CustomPath.promotion_update}/${row.id}`}>
            <NJVEditButton type={type} />
          </Link>
          <Button
            size={'middle'}
            type="primary"
            danger
            shape="circle"
            onClick={() => {
              setSelectedId(row.id);
              toggleModelOpen();
            }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (page) => {
    setPage(page - 1);
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Promotion'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to={CustomPath.promotion_form}>
            <NJVAddButton type={type} />
          </Link>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            allowClear
            placeholder="Search by promotion code"
            onChange={(e) => onChangeFilter('searchPromoCode', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVSelect
            bgcolor="#f1f1f1"
            allowClear
            size="large"
            placeholder="Search by promotion type"
            onChange={(e) => onChangeFilter('searchPromoType', e.target.value)}
          >
            {Object.entries(PromotionType).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </NJVSelect>
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {isFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={columns}
              dataSource={data}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
      <Modal
        title="Delete Promotion"
        open={isModelOpen}
        onOk={() => deleteRow(selectedId)}
        okType="danger"
        onCancel={toggleModelOpen}
      >
        <p>Are you sure want to delete?</p>
      </Modal>
    </>
  );
};

export default PromotionPage;
