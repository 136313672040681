import { Col, message, Row, Skeleton, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVEditButton,
  NJVInput,
  NJVTable,
  TitleLevel3,
} from '../../component/core-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const ChargingStationPage = () => {
  const type = '';
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);
  const [filterData, setFilterData] = useState({});

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      name: filterData?.searchFullName?.trim() ? filterData?.searchFullName?.trim() : '',
      address: filterData?.searchAddress?.trim() ? filterData?.searchAddress?.trim() : '',
    };
    ApiHandler({
      url: Api.charging_station_all,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        const content = response?.content;
        setData(content);
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
      })
      .catch((error) => {
        message.error(error);
      })
      .finally(() => toggleDataFetching(false));
  };

  const handleSearch = () => {
    fetchData();
  };

  useEffect(() => {
    toggleDataFetching();
    fetchData();
  }, []);

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Latitude',
      dataIndex: 'latitude',
    },
    {
      title: 'Longitude',
      dataIndex: 'longitude',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>
          {status === 'Active' ? (
            <Tag color="#87d068">{Constant.userStatus[status]}</Tag>
          ) : (
            <Tag color="#cd201f">{Constant.userStatus[status]}</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Action',
      textAlign: 'center',
      render: (row) => (
        <Space>
          <Link to={`${CustomPath.charging_station_update}/${row.id}`}>
            <NJVEditButton type={type} />
          </Link>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };
  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Charging Station'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to={CustomPath.charging_station_form}>
            <NJVAddButton type={type} />
          </Link>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by name"
            onChange={(e) => onChangeFilter('searchFullName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by address"
            onChange={(e) => onChangeFilter('searchAddress', e.target.value)}
          />
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {isFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={columns}
              dataSource={data}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ChargingStationPage;
