import { EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, ConfigProvider, DatePicker, Input, InputNumber, Select, Table, TimePicker } from 'antd';
import React from 'react';
import { userPermissions } from '../core/constant';
import Theme from './theme';
const EditableContext = React.createContext(null);

const { RangePicker } = DatePicker;

export const getRowClassName = (record, index) => {
  return index % 2 === 0 ? 'blue-row' : 'black-row';
};

export const TitleLevel3 = (props) => {
  const { label } = props;
  return <span style={{ fontSize: 27, fontWeight: '600' }}>{label}</span>;
};

export const NJVSpacer = ({ height, width }) => {
  return <div style={{ height, width, display: 'inline-block' }}></div>;
};

export const NJVAddButton = ({ handleClick, type, path }) => {
  const permission = userPermissions.find((permission) => permission.menuName === type);
  return permission?.write !== false ? (
    // <Link to={path}>
    <Button type="primary" icon={<PlusOutlined />} onClick={handleClick}>
      Create
    </Button>
  ) : // </Link>
  null;
};

export const NJVEditButton = ({ handleClick, type, path }) => {
  return userPermissions[type]?.update !== false ? (
    // <Link to={path}>
    <Button size={'middle'} type="primary" shape="circle">
      <EditOutlined />
    </Button>
  ) : // </Link>
  null;
};

export const NJVSearchButton = ({ onClick }) => {
  return (
    <Button type="primary" icon={<SearchOutlined />} shape="round" onClick={onClick}>
      Search
    </Button>
  );
};

export const NJVButton = ({ children, ...buttonProps }) => {
  const { bgcolor } = buttonProps;
  return (
    <ConfigProvider
      theme={{
        token: {},
      }}
    >
      <Button {...buttonProps} style={{ width: 150, backgroundColor: bgcolor, ...buttonProps.style, height: 41 }}>
        {children}
      </Button>
    </ConfigProvider>
  );
};

export const NJVInput = ({ ...inputProps }) => {
  const { bgcolor, ispasswordinput, istextareainput, issearchinput, style } = inputProps;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: '#fff',
          colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
          colorPrimaryHover: Theme.colors.light_gray,
          colorPrimary: 'rgb(128, 128, 128)',
        },
        components: {
          Input: {
            activeShadow: '0 0 0 0.5px rgb(128,128,128)',
            borderRadius: 9,
            borderRadiusLG: 9,
          },
          InputNumber: {
            activeShadow: '0 0 0 0.5px rgb(128,128,128)',
            borderRadius: 9,
            borderRadiusLG: 9,
          },
          Search: {
            activeShadow: '0 0 0 0.5px rgb(128,128,128)',
            borderRadius: 9,
            borderRadiusLG: 9,
          },
        },
      }}
    >
      {ispasswordinput ? (
        <Input.Password {...inputProps} style={{ ...style, height: 44 }} />
      ) : inputProps.isNumberInput ? (
        <InputNumber {...inputProps} style={{ ...style, height: 44 }} />
      ) : istextareainput ? (
        <Input.TextArea {...inputProps} style={{ height: 100, ...style }} />
      ) : issearchinput ? (
        <Input.Search {...inputProps} style={{ ...style, height: 44 }} />
      ) : (
        <Input {...inputProps} style={{ ...style, height: 44 }} />
      )}
    </ConfigProvider>
  );
};

export const NJVSelect = ({ children, ...selectProps }) => {
  const { bgcolor, style } = selectProps;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
          colorBorder: '#fff',
          colorPrimary: 'rgb(128, 128, 128)',
          colorPrimaryHover: '#fff',
        },
        components: {
          Select: {
            borderRadius: '9px',
            optionPadding: '4px 12px',
            optionSelectedBg: 'rgb(128, 128, 128)',
            optionSelectedColor: '#fff',
            optionHeight: 30,
          },
        },
      }}
    >
      <Select {...selectProps} style={{ ...style, height: 44 }} allowClear>
        {children}
      </Select>
    </ConfigProvider>
  );
};

export const NJVTimePicker = ({ ...datepickerProps }) => {
  const { bgcolor, style } = datepickerProps;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: '#fff',
          colorPrimaryHover: '#fff',
          cellWidth: 36,
          colorPrimary: 'rgb(128, 128, 128)',
        },
        components: {
          DatePicker: {
            colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
            activeShadow: '0 0 0 1px rgb(128, 128, 128)',
            borderRadius: '9px',
            activeBorderColor: '#fff',
          },
        },
      }}
    >
      <TimePicker {...datepickerProps} style={{ ...style, height: 44 }} />
    </ConfigProvider>
  );
};

export const NJVDatePicker = ({ ...datepickerProps }) => {
  const { bgcolor, style } = datepickerProps;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: '#fff',
          colorPrimaryHover: '#fff',
          cellWidth: 36,
          colorPrimary: 'rgb(128, 128, 128)',
        },
        components: {
          DatePicker: {
            colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
            activeShadow: '0 0 0 1px rgb(128, 128, 128)',
            borderRadius: '9px',
            activeBorderColor: '#fff',
          },
        },
      }}
    >
      <DatePicker {...datepickerProps} style={{ ...style, height: 44 }} />
    </ConfigProvider>
  );
};

export const NJVCard = ({ children, ...cardProps }) => {
  const { cardPadding, cardBgColor, cardShadow } = cardProps;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: `${cardBgColor ? cardBgColor : Theme.colors.card_bg_color}`,
          boxShadowTertiary: `${cardShadow ? cardShadow : 'none'}`,
          padding: `${cardPadding ? cardPadding : '15px'}`,
          paddingLG: `${cardPadding ? cardPadding : '15px'}`,
        },
        components: {
          Card: {
            headerHeight: 56,
          },
        },
      }}
    >
      <Card {...cardProps} bordered={false}>
        {children}
      </Card>
    </ConfigProvider>
  );
};

export const NJVTable = ({ ...tableProps }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: Theme.colors.light_gray_2,
        },
        components: {
          Table: {
            // headerBg: 'transparent',
            borderColor: 'transparent',
          },
        },
      }}
    >
      <Table {...tableProps} style={{ boxShadow: 'none' }} />
    </ConfigProvider>
  );
};

export const NJVReportTable = ({ ...tableProps }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: 'transparent',
            headerColor: '#fff',
            // rowHoverBg: 'rgba(194, 0, 47, 0.1)',
            cellPaddingBlock: '5px',
            borderColor: 'transparent',
          },
        },
      }}
    >
      <Table className="custom-table" {...tableProps} />
    </ConfigProvider>
  );
};

export const NJVRangePicker = ({ ...datepickerProps }) => {
  const { bgcolor, style } = datepickerProps;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: '#fff',
          colorPrimaryHover: '#fff',
          cellWidth: 36,
          colorPrimary: 'rgb(128, 128, 128)',
        },
        components: {
          DatePicker: {
            colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
            activeShadow: '0 0 0 1px rgb(128, 128, 128)',
            borderRadius: '9px',
            activeBorderColor: '#fff',
          },
        },
      }}
    >
      <RangePicker {...datepickerProps} style={{ ...style, height: 44 }} />
    </ConfigProvider>
  );
};
