export const SvgAssignFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="m1.5,4c0-1.105.895-2,2-2s2,.895,2,2-.895,2-2,2-2-.895-2-2Zm5.5,5c0-1.103-.897-2-2-2h-3c-1.103,0-2,.897-2,2v1h7v-1Zm13.5-3c1.105,0,2-.895,2-2s-.895-2-2-2-2,.895-2,2,.895,2,2,2Zm3.5,3c0-1.103-.897-2-2-2h-3c-1.103,0-2,.897-2,2v1h7v-1Zm-12-5c1.105,0,2-.895,2-2s-.895-2-2-2-2,.895-2,2,.895,2,2,2Zm3.5,3c0-1.103-.897-2-2-2h-3c-1.103,0-2,.897-2,2v1h7v-1Zm-1.5,4.107c0-.996-.681-1.92-1.664-2.08-1.253-.204-2.336.758-2.336,1.973v8.817c-1.076-.886-2.111-1.752-2.145-1.784-.922-.861-2.373-.813-3.235.109-.863.923-.819,2.372.098,3.23l2.886,2.693,15.396-.065v-4.5l-9-3.538v-4.855Z" />
    </svg>
  );
};

export const SvgDriverFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,3a9.012,9.012,0,0,1,8.306,5.536L14.024,9.793a10.705,10.705,0,0,1-4.19,0L3.683,8.562A9.013,9.013,0,0,1,12,3ZM3.006,12.307l3.154.631a4,4,0,0,1,2.733,2.018l.625,1.153a4.006,4.006,0,0,1,.482,1.9v2.763A9.017,9.017,0,0,1,3.006,12.307ZM14,20.776V18.013a4.006,4.006,0,0,1,.482-1.9l.625-1.153a4,4,0,0,1,2.733-2.018l3.154-.631A9.017,9.017,0,0,1,14,20.776Z" />
    </svg>
  );
};

export const SvgCalendarOutlined = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="M19,2h-1V1c0-.552-.448-1-1-1s-1,.448-1,1v1H8V1c0-.552-.448-1-1-1s-1,.448-1,1v1h-1C2.243,2,0,4.243,0,7v12c0,2.757,2.243,5,5,5h4c.552,0,1-.448,1-1s-.448-1-1-1H5c-1.654,0-3-1.346-3-3V10H23c.552,0,1-.448,1-1v-2c0-2.757-2.243-5-5-5Zm3,6H2v-1c0-1.654,1.346-3,3-3h14c1.654,0,3,1.346,3,3v1Zm-3.121,4.879l-5.707,5.707c-.755,.755-1.172,1.76-1.172,2.829v1.586c0,.552,.448,1,1,1h1.586c1.069,0,2.073-.417,2.828-1.172l5.707-5.707c.567-.567,.879-1.32,.879-2.122s-.312-1.555-.878-2.121c-1.134-1.134-3.11-1.134-4.243,0Zm2.828,2.828l-5.708,5.707c-.377,.378-.879,.586-1.414,.586h-.586v-.586c0-.534,.208-1.036,.586-1.414l5.708-5.707c.377-.378,1.036-.378,1.414,0,.189,.188,.293,.439,.293,.707s-.104,.518-.293,.707Zm-16.707-1.707c0-.552,.448-1,1-1h7c.552,0,1,.448,1,1s-.448,1-1,1H6c-.552,0-1-.448-1-1Zm6,4c0,.552-.448,1-1,1H6c-.552,0-1-.448-1-1s.448-1,1-1h4c.552,0,1,.448,1,1Z" />
    </svg>
  );
};

export const SvgVehicleFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="M0,11V8.5A1.5,1.5,0,0,1,1.5,7s.959,0,1,0A27.327,27.327,0,0,1,4.687,3.049,3.884,3.884,0,0,1,6.665,1.692c1.761-.856,8.909-.856,10.67,0a3.884,3.884,0,0,1,1.978,1.357A27.327,27.327,0,0,1,21.5,7c.041,0,1,0,1,0A1.5,1.5,0,0,1,24,8.5V11a90.636,90.636,0,0,0-12-1A90.636,90.636,0,0,0,0,11Zm24,2.023V15a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V13.027A90.835,90.835,0,0,1,12,12,90.835,90.835,0,0,1,24,13.027ZM6,16a1,1,0,0,0-2,0A1,1,0,0,0,6,16Zm14,0a1,1,0,0,0-2,0A1,1,0,0,0,20,16ZM6.942,21c.587,3.954-5.472,3.952-4.884,0Zm15,0c.587,3.954-5.472,3.952-4.884,0Z" />
    </svg>
  );
};

export const SvgAdminFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="m8,12c3.309,0,6-2.691,6-6S11.309,0,8,0,2,2.691,2,6s2.691,6,6,6Zm14.696,7.134l-.974-.562c.166-.497.278-1.019.278-1.572s-.111-1.075-.278-1.572l.974-.562c.478-.276.642-.888.366-1.366-.277-.479-.888-.643-1.366-.366l-.973.562c-.705-.794-1.644-1.375-2.723-1.594v-1.101c0-.552-.448-1-1-1s-1,.448-1,1v1.101c-1.079.22-2.018.801-2.723,1.594l-.973-.562c-.481-.277-1.09-.113-1.366.366-.276.479-.112,1.09.366,1.366l.974.562c-.166.497-.278,1.019-.278,1.572s.111,1.075.278,1.572l-.974.562c-.478.276-.642.888-.366,1.366.186.321.521.5.867.5.169,0,.341-.043.499-.134l.973-.562c.705.794,1.644,1.375,2.723,1.594v1.101c0,.552.448,1,1,1s1-.448,1-1v-1.101c1.079-.22,2.018-.801,2.723-1.594l.973.562c.158.091.33.134.499.134.346,0,.682-.179.867-.5.276-.479.112-1.09-.366-1.366Zm-5.696-.634c-.827,0-1.5-.673-1.5-1.5s.673-1.5,1.5-1.5,1.5.673,1.5,1.5-.673,1.5-1.5,1.5Zm-8.5-1.5c0-.549.055-1.106.165-1.658.066-.333-.042-.676-.285-.912-.243-.236-.589-.332-.92-.257-4.253.976-7.46,4.771-7.46,8.827,0,.552.448,1,1,1h8.637c.386,0,.738-.222.903-.571.166-.349.116-.762-.128-1.061-1.233-1.511-1.912-3.417-1.912-5.368Z" />
    </svg>
  );
};

export const SvgRoleFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="M3,6C3,2.691,5.692,0,9,0s6,2.691,6,6-2.691,6-6,6S3,9.309,3,6Zm21,12c0,3.314-2.686,6-6,6s-6-2.686-6-6,2.686-6,6-6,6,2.686,6,6Zm-2.288-1.702c-.383-.399-1.017-.41-1.414-.029l-2.713,2.624c-.143,.141-.379,.144-.522,.002l-1.354-1.331c-.396-.388-1.028-.381-1.414,.014-.387,.395-.381,1.027,.014,1.414l1.354,1.332c.46,.449,1.062,.674,1.663,.674s1.201-.225,1.653-.671l2.703-2.614c.398-.383,.411-1.016,.029-1.414Zm-11.712,1.702c0-1.368,.345-2.655,.95-3.781-.629-.14-1.28-.219-1.95-.219h0C4.044,14,.01,18.028,0,22.983c-.001,.557,.443,1.017,1,1.017H12.721c-1.665-1.466-2.721-3.607-2.721-6Z" />
    </svg>
  );
};

export const SvgRegionFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="m0,13.977V5C0,2.243,2.243,0,5,0h1v10.89L0,13.977ZM13.414,0h-5.414v11.086l5.914,5.914h10.086v-6.415c-.067.072-.128.148-.198.218l-1.854,1.815c-.922.901-2.146,1.398-3.448,1.398h-.007c-1.297,0-2.52-.497-3.441-1.399l-1.831-1.792c-1.438-1.436-2.221-3.322-2.221-5.326s.781-3.888,2.198-5.304c.069-.069.145-.13.216-.196Zm-1.235,18.094l-5.37-5.37-6.809,3.504v2.773c0,2.757,2.243,5,5,5h2.75l4.43-5.906Zm-1.93,5.906h8.751c2.757,0,5-2.243,5-5h-10l-3.75,5ZM24,5.5c0,1.47-.572,2.851-1.611,3.889l-1.84,1.801c-.564.553-1.307.828-2.049.828-.742,0-1.484-.275-2.049-.828l-1.832-1.793c-1.047-1.046-1.619-2.427-1.619-3.896s.572-2.851,1.611-3.889c1.038-1.039,2.419-1.611,3.889-1.611s2.85.572,3.889,1.611c1.039,1.038,1.611,2.42,1.611,3.889Zm-4-.006c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5,1.5.672,1.5,1.5,1.5,1.5-.672,1.5-1.5Z" />
    </svg>
  );
};

export const SvgFAQFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="m8,19.998h8v.31c0,2.035-1.655,3.69-3.69,3.69h-.619c-2.035,0-3.69-1.655-3.69-3.69v-.31Zm13-11c0,2.59-1.118,5.055-3.068,6.763-.739.648-1.275,1.413-1.594,2.237h-.838v.002h-7v-.002h-.937c-.358-.877-.926-1.702-1.695-2.417-2.149-2.001-3.167-4.83-2.793-7.761C3.59,3.78,6.904.515,10.956.057c2.567-.289,5.13.522,7.038,2.227,1.91,1.707,3.006,4.154,3.006,6.714Zm-7.5,4.502c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5,1.5.672,1.5,1.5,1.5,1.5-.672,1.5-1.5Zm1.453-7.034c-.212-1.212-1.207-2.207-2.417-2.418-.886-.162-1.783.081-2.464.653-.682.572-1.072,1.41-1.072,2.299,0,.553.447,1,1,1s1-.447,1-1c0-.297.131-.576.358-.767.229-.194.522-.273.832-.216.391.068.724.401.793.794.093.53-.213.905-.502,1.065-.443.244-1.481.815-1.481,2.123,0,.553.447,1,1,1s1-.447,1-1c0-.053,0-.125.447-.371,1.125-.621,1.729-1.893,1.506-3.163Z" />
    </svg>
  );
};

export const SvgNotificationChannelFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="M24,20c0,.55-.45,1-1,1h-2v2c0,.55-.45,1-1,1s-1-.45-1-1v-2h-2c-.55,0-1-.45-1-1s.45-1,1-1h2v-2c0-.55,.45-1,1-1s1,.45,1,1v2h2c.55,0,1,.45,1,1Zm-9.91-1H5c-1.19,0-2.31-.52-3.07-1.43-.76-.91-1.08-2.1-.87-3.27l1.28-6.18c.42-2.36,2.45-4.08,4.84-4.11-.11-.32-.19-.65-.19-1.01,0-1.65,1.35-3,3-3h4c1.65,0,3,1.35,3,3,0,.35-.07,.69-.19,1.01,2.39,.04,4.42,1.75,4.84,4.11l1.28,6.18c.03,.16,.02,.33,.03,.49-.88-.5-1.89-.79-2.97-.79-2.97,0-5.43,2.16-5.91,5Zm-.09-15c.55,0,1-.45,1-1s-.45-1-1-1h-4c-.55,0-1,.45-1,1s.45,1,1,1h4Zm-5.86,17c.45,1.72,2,3,3.86,3,1.07,0,2.02-.43,2.74-1.12-.32-.58-.54-1.21-.65-1.88h-5.95Z" />
    </svg>
  );
};

export const SvgNotificationFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="M4.068,18H19.724a3,3,0,0,0,2.821-4.021L19.693,6.094A8.323,8.323,0,0,0,11.675,0h0A8.321,8.321,0,0,0,3.552,6.516l-2.35,7.6A3,3,0,0,0,4.068,18Z" />
      <path d="M7.1,20a5,5,0,0,0,9.8,0Z" />
    </svg>
  );
};

export const SvgPassengerFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="M15,2.5c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5-1.119,2.5-2.5,2.5-2.5-1.119-2.5-2.5Zm8.895,20.053l-1.866-3.732c-.175-.349-.446-.638-.786-.836l-3.111-1.801,.572-3.99,2.778,1.544c.157,.09,.328,.132,.496,.132,.347,0,.684-.181,.869-.503,.274-.479,.108-1.091-.371-1.365l-3.466-1.938,.094-.657c.117-.858-.143-1.726-.712-2.379-.412-.472-1.154-1.026-1.957-1.026,0,0-.505,.016-.599,.045-.984,.147-1.88,.656-2.521,1.453l-3.561,4.524c-.342,.434-.267,1.062,.167,1.404,.435,.34,1.063,.268,1.404-.168l2.695-3.423-.572,3.029c-.227,1.524,.454,3.062,1.789,3.951l5.003,2.899,1.866,3.732c.175,.351,.528,.553,.896,.553,.15,0,.303-.034,.446-.105,.494-.247,.694-.848,.447-1.342Zm-9.372-4.54c-.547-.085-1.061,.276-1.152,.82l-.711,4.122c-.091,.545,.276,1.061,.821,1.152,.056,.009,.112,.014,.167,.014,.479,0,.903-.346,.985-.834l.711-4.122c.091-.545-.276-1.061-.821-1.152Zm-9.522,1.988c-1.105,0-2,.895-2,2s.895,2,2,2,2-.895,2-2-.895-2-2-2Zm3.488-6.201l-.71-.596c-1.442-1.119-3.54-.867-4.677,.563l-2.373,2.984c-1.096,1.378-.935,3.31,.346,4.522,.344-1.859,1.97-3.273,3.926-3.273,1.267,0,2.385,.604,3.118,1.526l.918-1.205c.551-.7,.799-1.544,.696-2.376-.104-.843-.556-1.613-1.244-2.146Z" />
    </svg>
  );
};

export const SvgZoneFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="m16.949,2.05c-1.321-1.322-3.079-2.05-4.949-2.05s-3.628.728-4.95,2.05c-2.729,2.729-2.729,7.17.008,9.907l2.495,2.44c.675.66,1.561.99,2.447.99s1.772-.33,2.447-.99l2.502-2.448c1.322-1.322,2.051-3.08,2.051-4.95s-.729-3.627-2.051-4.95Zm-4.949,7.94c-1.657,0-3-1.343-3-3s1.343-3,3-3,3,1.343,3,3-1.343,3-3,3Zm12,6.772c.002.354-.183.682-.485.863l-9.861,5.917c-.51.306-1.082.459-1.653.459s-1.144-.153-1.653-.459L.485,17.625c-.303-.182-.487-.51-.485-.863.002-.353.19-.679.495-.857l4.855-2.842c.1.11.203.219.309.325l2.495,2.439c1.028,1.006,2.395,1.561,3.846,1.561s2.817-.555,3.846-1.561l2.518-2.463c.098-.098.194-.199.287-.301l4.854,2.841c.305.179.493.505.495.857Z" />
    </svg>
  );
};

export const SvgStationFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="M23,2a1,1,0,0,0-1,1V4H20V3a1,1,0,0,0-2,0V5.5a2.5,2.5,0,0,0,2,2.45V17a2,2,0,0,1-2,2H16V11H14a1,1,0,0,1,0-2h2V5a5.006,5.006,0,0,0-5-5H5A5.006,5.006,0,0,0,0,5V9H2a1,1,0,0,1,0,2H0v8a5.006,5.006,0,0,0,5,5h6a5.008,5.008,0,0,0,4.582-3H18a4,4,0,0,0,4-4V7.95A2.5,2.5,0,0,0,24,5.5V3A1,1,0,0,0,23,2ZM10.732,11.49,8.85,14.527a1,1,0,0,1-1.7-1.054L8.683,11H6.615A1.614,1.614,0,0,1,5.07,8.917a1,1,0,0,1,.1-.234L7.148,5.476a1,1,0,1,1,1.7,1.048L7.328,9H9.4a1.6,1.6,0,0,1,1.336,2.49Z" />
    </svg>
  );
};
export const SvgAvailabilityCheck = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="m23.553,22.139l-2.666-2.666c.698-.981,1.113-2.177,1.113-3.473,0-3.314-2.686-6-6-6s-6,2.686-6,6,2.686,6,6,6c1.296,0,2.492-.415,3.473-1.113l2.666,2.666c.195.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023,0-1.414Zm-8.226-3.142c-.601,0-1.204-.225-1.664-.674l-1.132-1.108c-.395-.387-.401-1.02-.015-1.414.386-.395,1.019-.401,1.414-.016l1.132,1.108c.144.142.379.141.522,0l2.713-2.624c.398-.381,1.032-.37,1.414.029.383.398.37,1.031-.029,1.414l-2.703,2.614c-.452.446-1.052.671-1.653.671Zm-7.327-2.997c0-4.411,3.589-8,8-8,1.458,0,2.822.398,4,1.083v-4.083c0-2.757-2.244-5-5-5H4.999C2.242,0,0,2.244,0,5.001v13.999c0,2.757,2.244,5,5,5h11c-4.411,0-8-3.589-8-8Zm2-11h5c.552,0,1,.447,1,1s-.448,1-1,1h-5c-.552,0-1-.447-1-1s.448-1,1-1Zm-4,12h-1c-.552,0-1-.447-1-1s.448-1,1-1h1c.552,0,1,.447,1,1s-.448,1-1,1Zm0-5h-1c-.552,0-1-.447-1-1s.448-1,1-1h1c.552,0,1,.447,1,1s-.448,1-1,1Zm0-5h-1c-.552,0-1-.447-1-1s.448-1,1-1h1c.552,0,1,.447,1,1s-.448,1-1,1Z" />
    </svg>
  );
};
export const SvgSosFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="m3.136,6.317L.389,3.687l1.384-1.444,2.747,2.631-1.384,1.444Zm5.298-2.473L7.896.052l-1.98.281.538,3.792,1.98-.281ZM18.084.333l-1.98-.281-.538,3.792,1.98.281.538-3.792Zm5.526,3.353l-1.384-1.444-2.747,2.631,1.384,1.444,2.747-2.631Zm.389,19.313c0-1.654-1.346-3-3-3H3c-1.654,0-3,1.346-3,3v1h24v-1ZM3.031,18l1.149-8.506c.249-1.988,1.956-3.494,3.968-3.494h7.703c2.013,0,3.719,1.506,3.969,3.504l1.148,8.496H3.031Zm4.576-4h2.018l.27-2h2.104v-2h-3.852l-.54,4Z" />
    </svg>
  );
};
export const SvgPointFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="M9,4c0-2.209,3.358-4,7.5-4s7.5,1.791,7.5,4-3.358,4-7.5,4-7.5-1.791-7.5-4Zm7.5,6c-1.027,0-2.001-.115-2.891-.315-1.359-1.019-3.586-1.685-6.109-1.685-4.142,0-7.5,1.791-7.5,4s3.358,4,7.5,4,7.5-1.791,7.5-4c0-.029-.007-.057-.008-.086h.008v2.086c0,2.209-3.358,4-7.5,4S0,16.209,0,14v2c0,2.209,3.358,4,7.5,4s7.5-1.791,7.5-4v2c0,2.209-3.358,4-7.5,4S0,20.209,0,18v2c0,2.209,3.358,4,7.5,4s7.5-1.791,7.5-4v-.08c.485,.052,.986,.08,1.5,.08,4.142,0,7.5-1.791,7.5-4v-2c0,2.119-3.092,3.849-7,3.987v-2c3.908-.138,7-1.867,7-3.987v-2c0,2.119-3.092,3.849-7,3.987v-2c3.908-.138,7-1.867,7-3.987v-2c0,2.209-3.358,4-7.5,4Z" />
    </svg>
  );
};

export const SvgPushFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="m0,22h4v2H0v-2Zm6,2h4v-2h-4v2Zm14-8H6c-2.206,0-4-1.794-4-4s1.794-4,4-4h3v-2h-3c-3.309,0-6,2.691-6,6s2.691,6,6,6h14c1.103,0,2,.897,2,2s-.897,2-2,2h-8v2h8c2.206,0,4-1.794,4-4s-1.794-4-4-4Zm-.5-9l-8.231,1.295-1.269,3.738c-.002.508,0,1.968,1.881,1.968.326,0,.651-.089.943-.267l11.104-6.735L12.827.267C12.57.11,12.289.003,12,.003,10,.003,10,1.64,10,1.96l1.256,3.784,8.244,1.256Z" />
    </svg>
  );
};

export const SvgHistoryFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 24 24" width={width} height={height} fill={color}>
      <path d="M12,0A12.034,12.034,0,0,0,4.04,3.04L2.707,1.707A1,1,0,0,0,1,2.414V7A1,1,0,0,0,2,8H6.586a1,1,0,0,0,.707-1.707L6.158,5.158A9,9,0,0,1,21,12.26,9,9,0,0,1,3.1,13.316,1.51,1.51,0,0,0,1.613,12h0A1.489,1.489,0,0,0,.115,13.663,12.018,12.018,0,0,0,12.474,23.991,12.114,12.114,0,0,0,23.991,12.474,12.013,12.013,0,0,0,12,0Z" />
      <path d="M11.5,7h0A1.5,1.5,0,0,0,10,8.5v4.293a2,2,0,0,0,.586,1.414L12.379,16A1.5,1.5,0,0,0,14.5,13.879l-1.5-1.5V8.5A1.5,1.5,0,0,0,11.5,7Z" />
    </svg>
  );
};

export const SvgChatHistoryFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 25 24" width={width} height={height} fill={color}>
      <path d="m3,12H0C0,5.383,5.383,0,12,0c3.204,0,6.238,1.302,8.47,3.53l2.53-2.53v5.909c0,.603-.488,1.091-1.091,1.091h-5.909l2.352-2.352c-1.674-1.671-3.95-2.648-6.352-2.648C7.038,3,3,7.037,3,12Zm13.783,5.874c.526.336,1.215-.041,1.215-.665l.002-5.707c0-.828-.671-1.5-1.499-1.5l-5.001-.003c-.829,0-1.5.671-1.5,1.5v4.5h4.5l2.283,1.874Zm-5.281-9.874h2.498s0-.5,0-.5c0-.829-.672-1.5-1.501-1.5l-5.001.003c-.828,0-1.499.672-1.498,1.5v5.707c.001.624.691,1.001,1.216.665l.783-.643v-1.732c0-.936.365-1.815,1.027-2.477.661-.66,1.539-1.023,2.472-1.023h.004Zm9.498,4c0,4.963-4.038,9-9,9-2.402,0-4.678-.976-6.352-2.648l2.352-2.352H2.091c-.602,0-1.091.488-1.091,1.091v5.909l2.53-2.53c2.232,2.228,5.266,3.53,8.47,3.53,6.617,0,12-5.383,12-12h-3Z" />
    </svg>
  );
};

export const SvgPickupFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 25 24" width={width} height={height} fill={color}>
      <path d="m13,16.462l9,3.538v4H5.538l-1.821-1.628c-.917-.858-.96-2.307-.098-3.23.861-.922,2.313-.97,3.235-.109.034.032,1.069.898,2.145,1.784v-8.817c0-1.215,1.083-2.176,2.336-1.973.983.16,1.664,1.083,1.664,2.08v4.355ZM5,0C2.239,0,0,2.239,0,5s2.239,5,5,5,5-2.239,5-5S7.761,0,5,0Zm1.784,7.667l-.411-1.2h-2.748l-.41,1.2h-1.237l1.723-5.131c.317-.721.748-.868,1.298-.869.55,0,.981.148,1.29.847l1.731,5.153h-1.237Zm-1.658-4.794c-.025-.003-.065-.006-.126-.006-.059,0-.098.003-.123.006-.014.02-.034.053-.058.104l-.783,2.29h1.927l-.79-2.31c-.019-.039-.035-.066-.046-.083Zm13.024.799c0-.33-.269-.6-.6-.6h-1.714c-.047,0-.086.039-.086.086v1.114h1.8c.331,0,.6-.27.6-.6Zm-1.15-3.672c-2.761,0-5,2.239-5,5s2.239,5,5,5,5-2.239,5-5S19.761,0,17,0Zm1.15,7.872h-2.314c-.709,0-1.286-.577-1.286-1.286v-3.429c0-.709.577-1.286,1.286-1.286h1.714c.993,0,1.8.807,1.8,1.8,0,.321-.084.622-.232.884.5.32.832.88.832,1.516,0,.993-.807,1.8-1.8,1.8Zm0-1.2c.331,0,.6-.27.6-.6s-.269-.6-.6-.6h-2.4v1.114c0,.047.038.086.086.086h2.314Z" />
    </svg>
  );
};

export const SvgWaitingChargeFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 25 24" width={width} height={height} fill={color}>
      <path d="m14.782,19.319c.979.432,2.059.677,3.196.68.006.947-.31,1.873-.94,2.605-.765.888-1.874,1.396-3.044,1.396H4.004c-1.17,0-2.279-.51-3.043-1.398-.753-.874-1.087-2.024-.917-3.157.406-2.699,1.885-5.198,4.401-7.444C1.928,9.754.449,7.255.043,4.556c-.17-1.133.165-2.283.917-3.157C1.725.51,2.834,0,4.004,0h9.99c1.17,0,2.279.508,3.044,1.396.63.731.946,1.658.94,2.605-4.401.012-7.978,3.595-7.978,7.999,0,.656.088,1.29.238,1.901l-.612-.488c-.365-.29-.88-.29-1.245-.002l-.704.559c-1.557,1.231-3.619,3.212-4.605,5.656-.125.309-.087.658.098.934.186.275.497.44.829.44h10c.333,0,.644-.164.829-.44.186-.275.223-.626.098-.934-.042-.105-.097-.203-.143-.306Zm9.218-7.319c0,3.309-2.691,6-6,6s-6-2.691-6-6,2.691-6,6-6,6,2.691,6,6Zm-3.793.793l-1.207-1.207v-2.086c0-.552-.448-1-1-1s-1,.448-1,1v2.5c0,.265.105.52.293.707l1.5,1.5c.195.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023,0-1.414Zm-14.561,6.206h6.702c-.924-1.429-2.299-2.691-3.348-3.527-1.047.828-2.432,2.095-3.354,3.527Z" />
    </svg>
  );
};

export const SvgDistanceChargeFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 25 24" width={width} height={height} fill={color}>
      <path d="M19,11.994l-3.524-3.447c-1.961-1.961-1.961-5.133-.012-7.083,.945-.944,2.201-1.464,3.536-1.464s2.591,.52,3.535,1.464c.945,.944,1.465,2.2,1.465,3.536s-.52,2.591-1.465,3.536l-3.535,3.458Zm0-8.994c-.534,0-1.037,.208-1.415,.586-.779,.779-.779,2.048,0,2.828l1.415,1.383,1.426-1.395c.366-.366,.574-.868,.574-1.402s-.208-1.037-.586-1.415c-.378-.377-.88-.585-1.414-.585ZM5,23.994l-3.524-3.447c-1.961-1.961-1.961-5.133-.012-7.083,.945-.944,2.201-1.464,3.536-1.464s2.591,.52,3.535,1.464c.945,.944,1.465,2.2,1.465,3.536s-.52,2.591-1.465,3.536l-3.535,3.458Zm0-8.994c-.534,0-1.037,.208-1.415,.586-.779,.779-.779,2.048,0,2.828l1.415,1.383,1.426-1.395c.366-.366,.574-.868,.574-1.402s-.208-1.037-.586-1.415c-.378-.377-.88-.585-1.414-.585Zm14.5,0h-4c-.827,0-1.5-.673-1.5-1.5s.673-1.5,1.5-1.5h.646l-2.069-2.023c-.171-.171-.331-.348-.481-.531-1.547,.727-2.597,2.291-2.597,4.055,0,2.481,2.019,4.5,4.5,4.5h4c.827,0,1.5,.673,1.5,1.5s-.673,1.5-1.5,1.5H10.744c-.235,.336-.499,.654-.794,.95l-2.096,2.05h11.646c2.481,0,4.5-2.019,4.5-4.5s-2.019-4.5-4.5-4.5Z" />
    </svg>
  );
};

export const SvgTimeFareFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm3.964,10.866l-3.464,2c-.155,.089-.327,.134-.5,.134s-.345-.045-.5-.134c-.31-.179-.5-.509-.5-.866V6c0-.553,.448-1,1-1s1,.447,1,1v4.268l1.964-1.134c.477-.277,1.089-.112,1.366,.366,.276,.479,.112,1.09-.366,1.366Z" />
    </svg>
  );
};

export const SvgWeatherFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="M13.892,24c-1.96,0-3.837-.008-4.491-.026a3.749,3.749,0,0,1-.834-.121,3.5,3.5,0,0,1-.449-6.588,6.366,6.366,0,0,1,.057-2.732,6,6,0,0,1,11.368-.855c.136.326.763.469.763.469h0a5.014,5.014,0,0,1,3.559,6,4.951,4.951,0,0,1-3.7,3.7,5.283,5.283,0,0,1-1.064.131C18.229,23.99,16.013,24,13.892,24ZM8.987,9.755a7.93,7.93,0,0,1,7.178-1.476,1.928,1.928,0,0,0,1.493-.21L20.105,6.64A1,1,0,1,0,19,4.972l-2.641,1.75c-.554.281-.843-.442-1.27-.717l1.843-3.146a1,1,0,0,0-1.727-1.011L13.384,4.963A6.137,6.137,0,0,0,12,4.577V1a1,1,0,0,0-2,0V4.58a6.5,6.5,0,0,0-1.326.363L6.831,1.834A1,1,0,0,0,5.11,2.854L6.944,5.947a6.718,6.718,0,0,0-.951.934l-3.1-1.814a1,1,0,1,0-1.01,1.725L4.962,8.6A6.206,6.206,0,0,0,4.575,10H1a1,1,0,0,0,0,2H4.576a6.425,6.425,0,0,0,.2.882.5.5,0,0,1-.3.54L1.768,15.034a1,1,0,0,0,1.023,1.719l2.59-1.513a1.739,1.739,0,0,0,.83-1.094A7.981,7.981,0,0,1,8.987,9.755Z" />
    </svg>
  );
};

export const SvgPeakHourFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      id="Layer_1"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
    >
      <path d="m14.181.207a1 1 0 0 0 -1.181.983v2.879a8.053 8.053 0 1 0 6.931 6.931h2.886a1 1 0 0 0 .983-1.181 12.047 12.047 0 0 0 -9.619-9.612zm1.819 12.793h-2.277a1.994 1.994 0 1 1 -2.723-2.723v-3.277a1 1 0 0 1 2 0v3.277a2 2 0 0 1 .723.723h2.277a1 1 0 0 1 0 2zm-13.014-8.032a1 1 0 1 1 -1.17.8 1 1 0 0 1 1.17-.8zm-1.6 3.987a1 1 0 1 1 -1.17.8 1 1 0 0 1 1.167-.8zm8.742 12.868a1 1 0 1 1 -1.17.794 1 1 0 0 1 1.167-.794zm-4.12-19.923a1 1 0 1 1 -1.17.8 1 1 0 0 1 1.17-.8zm4.174-1.691a1 1 0 1 1 -1.182.771 1 1 0 0 1 1.182-.771zm-9.948 13.837a1 1 0 1 1 .8 1.17 1 1 0 0 1 -.8-1.17zm1.681 3.963a1 1 0 1 1 .8 1.17 1 1 0 0 1 -.8-1.17zm3.052 2.991a1 1 0 1 1 .8 1.17 1 1 0 0 1 -.8-1.17zm16.047-1.967a1 1 0 1 1 1.17-.8 1 1 0 0 1 -1.17.799zm-3.022 3.067a1 1 0 1 1 1.17-.8 1 1 0 0 1 -1.17.8zm-3.939 1.656a1 1 0 1 1 1.17-.795 1 1 0 0 1 -1.17.791zm9.659-9.756a1 1 0 1 1 -1-1 1 1 0 0 1 1 1z" />
    </svg>
  );
};

export const SvgBookingFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 25 24" width={width} height={height} fill={color}>
      <path d="m24,3.538v16.462h-.593c-2.055,0-3.901-1.258-4.653-3.171l-2.292-5.829h-5.462c-.996,0-1.92-.681-2.08-1.664-.204-1.253.758-2.336,1.973-2.336h9.924c-.886-1.076-1.752-2.111-1.784-2.145-.861-.922-.813-2.373.109-3.235.923-.863,2.372-.819,3.23.098l1.628,1.821Z" />
      <path d="m0,17v2c0,2.757,2.243,5,5,5h6c2.757,0,5-2.243,5-5v-2H0Zm9,4.5h-2c-.552,0-1-.448-1-1s.448-1,1-1h2c.552,0,1,.448,1,1s-.448,1-1,1Z" />
      <path d="m7,9c0-2.209,1.791-4,4-4h5c0-2.761-2.239-5-5-5h-6C2.239,0,0,2.239,0,5v10h16v-2h-5c-2.209,0-4-1.791-4-4Z" />
    </svg>
  );
};

export const SvgPromoFilled = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
    >
      <path d="M24,12c0-1.626-.714-3.16-1.925-4.124,.14-1.622-.44-3.211-1.59-4.362-1.15-1.149-2.735-1.731-4.277-1.555-2.014-2.556-6.365-2.604-8.332-.035C4.643,1.54,1.534,4.584,1.96,7.792c-2.556,2.014-2.605,6.365-.035,8.333-.14,1.622,.44,3.211,1.59,4.362,1.15,1.149,2.737,1.731,4.277,1.555,2.014,2.556,6.365,2.604,8.332,.035,1.62,.139,3.21-.439,4.361-1.59,1.149-1.15,1.729-2.74,1.555-4.277,1.246-1.048,1.96-2.582,1.96-4.208Zm-16-3c.006-1.308,1.994-1.308,2,0-.006,1.308-1.994,1.308-2,0Zm2.832,6.555c-.308,.463-.933,.581-1.387,.277-.46-.306-.584-.927-.277-1.387l4-6c.306-.459,.926-.585,1.387-.277,.46,.306,.584,.927,.277,1.387l-4,6Zm4.168,.445c-1.308-.006-1.308-1.994,0-2,1.308,.006,1.308,1.994,0,2Z" />
    </svg>
  );
};
