import { Breadcrumb, Button, Card, Checkbox, Col, Form, Image, Row, Select, Space, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NJVButton, NJVInput, NJVSelect } from '../../../component/core-component';
import Theme from '../../../component/theme';
import CustomPath from '../../../route/custom-path';
import { ApiHandler } from '../../../network/network-manager';
import Api from '../../../network/api';
import { HTTP_METHOD } from '../../../network/httpMethod';
import { MEDIA_TYPE } from '../../../network/mediaType';
import { useParams } from 'react-router-dom';
import { PasswordValidator, PhoneNumberValidator } from '../../../core/validator';
import { useToggle } from 'react-use';
import { UserStatus } from '../../../core/constant';
import { getBase64 } from '../../../core/utils';
import ProfileUploadButton from '../../../component/profile-upload-button';

function AdminUpdate() {
  const navigate = useNavigate();

  const { id } = useParams();

  const fromRef = useRef();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useToggle(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadedFile, setUploadedFile] = useState();
  const [role, setRole] = useState([]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const fetchRole = (pageNumber) => {
    ApiHandler({
      url: Api.role_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    })
      .then((response) => {
        setRole(response?.content);
      })
      .catch(() => {})
      .finally();
  };

  useEffect(() => {
    findAdmin(id);
    fetchRole();
  }, []);

  const findAdmin = () => {
    ApiHandler({
      url: Api.admin,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        if (response.status && response.status === 'Active') {
          response['active'] = true;
        } else {
          response['active'] = false;
        }
        response = { ...response, password: '' };
        setFileList(response.profileUrl ? [{ url: response.profileUrl }] : []);
        setData(response);
        fromRef.current.setFieldsValue(response);
      })
      .catch(() => setLoading(false));
  };

  const updateAdmin = async (formData) => {
    setLoading(true);
    formData = {
      ...formData,
      id: data.id,
      password: formData.password ? formData.password : undefined,
      position: formData.position ? formData.position : undefined,
      status: formData.active ? 'Active' : 'Inactive',
    };
    const requestData = new FormData();
    Object.keys(formData).forEach((key) => {
      formData[key] && requestData.append(key, formData[key]);
    });
    uploadedFile && requestData.append('file', uploadedFile);

    await ApiHandler({
      url: Api.admin,
      method: HTTP_METHOD.PUT,
      mediaType: MEDIA_TYPE.FORM_DATA,
      requestData,
    })
      .then((response) => {
        backToHome();
      })
      .catch((error) => setLoading(false));
  };

  const handleDeactivate = () => {
    setIsSubmitting();
    const requestFormData = new FormData();
    requestFormData.append('id', data.id);
    requestFormData.append('status', UserStatus.Deactivate);
    ApiHandler({
      url: Api.admin,
      method: HTTP_METHOD.PUT,
      requestData: requestFormData,
    })
      .then(() => {
        backToHome();
      })
      .catch(() => {})
      .finally(setIsSubmitting);
  };

  const backToHome = () => navigate(CustomPath.admin);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.admin}>Admin</Link>,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'Admin Update'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form layout="vertical" initialValues={{ active: true }} onFinish={updateAdmin} ref={fromRef}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item>
                    <Upload
                      beforeUpload={(file) => {
                        setUploadedFile(file);
                        return false;
                      }}
                      onRemove={() => {
                        setUploadedFile(undefined);
                      }}
                      listType="picture-circle"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length === 1 ? null : <ProfileUploadButton />}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="fullName" label={'Name'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter name'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phoneNumber"
                    label={'Phone Number'}
                    rules={[{ required: true, message: '*(requried)' }, { validator: PhoneNumberValidator }]}
                  >
                    <NJVInput placeholder={'0979xxxxxxxx'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="emailAddress"
                    label={'Email Address'}
                    rules={[{ required: true, message: '*(requried)' }, { type: 'email' }]}
                  >
                    <NJVInput placeholder={'Enter email address'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="password"
                    label={'Password'}
                    style={{ cursor: 'pointer' }}
                    rules={[{ validator: PasswordValidator }]}
                  >
                    <NJVInput ispasswordinput={true} placeholder="Enter password" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="position" label={'Position'}>
                    <NJVInput placeholder={'Enter position'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="role" label={'Role'}>
                    <NJVSelect size="large" placeholder="Select Role" style={{ width: '100%' }}>
                      {role?.map((role) => (
                        <Select.Option key={role.id} value={role.id}>
                          {role.name}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="active" valuePropName="checked" label="Active">
                    <Checkbox>Active</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Space>
                  <NJVButton type="primary" htmlType="submit" loading={loading}>
                    Update
                  </NJVButton>
                  <NJVButton type="primary" loading={isSubmitting} danger onClick={handleDeactivate}>
                    Deactivate
                  </NJVButton>
                </Space>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  );
}

export default AdminUpdate;
