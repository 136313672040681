import { Avatar, Button, Col, ConfigProvider, Image, Layout, Menu, notification, Popover, Row } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import MenuItem from 'antd/es/menu/MenuItem';
import React, { useEffect, useState } from 'react';

import { UserOutlined } from '@ant-design/icons';
import { theme } from 'antd';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { createGlobalState } from 'react-use';
import './App.css';
import { logout, storeUserInfo } from './core/auth';
import { SIDE_MENU } from './core/constant';
import { GenerateSvgIconBaseOnName, unselectColor } from './core/utils';
import CustomPath from './route/custom-path';

import { getToken, onMessage } from 'firebase/messaging';

import { useLocation } from 'react-router-dom';
import { SvgNotificationFilled } from './component/custom-svg-icon';
import Theme from './component/theme';
import { messaging } from './config/firebase-config';
import Api from './network/api';
import { HTTP_METHOD } from './network/httpMethod';
import { ApiHandler } from './network/network-manager';

const fcm_vapi = 'BEBdih2FuQATsW2qehtm5IpQbdY_ZBbuE5HrPsNbRGx1TH9qSJTi_z5_fiBnbAr180d_VFP_JukRmnCse8CXUK8';
const { Header } = Layout;

export const useUserInfo = createGlobalState({});

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useUserInfo();
  const currentSelectedMenuIndex = 1;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openQR, setOpenQR] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(false);
  const [clickMenu, setClickMenu] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [previousMenuHeader, setPreviousMenuHeader] = useState();
  const logo = require('./assets/zeimm_logo.png');
  const handleSelectMenu = (event) => {};
  const handleMenuClick = (route) => {};

  const fetchUserInfo = () => {
    if (!userInfo.accessToken) return;
    ApiHandler({
      url: Api.admin,
      method: HTTP_METHOD.GET,
      specificId: userInfo.userId,
    }).then((res) => {
      storeUserInfo({ ...userInfo, profileUrl: res.profileUrl });
      setUserInfo({ ...userInfo, profileUrl: res.profileUrl });
    });
  };

  useEffect(() => {
    requestPermission();
    onMessage(messaging, (payload) => {
      console.log('ON Message => ', payload);
      openNotification(payload);
    });
  }, []);

  const handleLogout = () => {
    logout();
    setUserInfo({});
    navigate(CustomPath.login);
  };
  const requestPermission = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        getToken(messaging, {
          vapidKey: fcm_vapi,
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log(currentToken);
              updateDeviceToken(currentToken);
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
      } else if (permission === 'denied') {
        alert('You denied for the notification');
      }
    });
  };

  const updateDeviceToken = (deviceToken) => {
    ApiHandler({
      url: Api.user_update_device_token,
      method: HTTP_METHOD.POST,
      requestParams: {
        deviceType: 'web',
        token: deviceToken,
      },
      disableShowMessage: true,
    })
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => console.log(err));
  };

  const openNotification = (data) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        onClick={() => {
          navigate(CustomPath.sos);
          notification.destroy();
        }}
      >
        View Sos
      </Button>
    );
    if (data?.notification?.title && data?.notification?.body) {
      if (Notification.permission === 'granted') {
        new Notification(data.notification.title, {
          body: data.notification.body,
        });
      }
      notification.open({
        message: data.notification.title,
        description: data.notification.body,
        btn,
        key,
        duration: 0,
      });
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              bodyBg: '#fff',
            },
            Menu: {
              itemBorderRadius: 0,
              itemSelectedBg: '#3fcca4',
            },
            Button: {
              primaryShadow: 'none',
              defaultShadow: 'none',
            },
          },
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <ScrollToTop />
          <Layout
            style={{
              transform: 'scale(0.9)',
              transformOrigin: 'top left',
              width: '111.11vw',
              height: '111.11vh',
              position: 'absolute',
              top: '0',
              left: '0',
            }}
          >
            <Header
              style={{
                height: '70px',
                position: 'fixed',
                top: 0,
                zIndex: 1,
                width: '100%',
                boxShadow: '1px 1px 20px rgba(0, 0, 0, 0.1)',
                padding: 0,
                background: colorBgContainer,
              }}
            >
              <Row style={{ width: '100%', height: 70 }}>
                <Col span={12}>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
                    <Link to={CustomPath.dashboard}>
                      <Image width={150} src={logo} preview={false} style={{ marginBottom: 10 }} />
                    </Link>
                  </div>
                </Col>
                {/* <Col
                  span={10}
                  style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', cursor: 'pointer' }}
                >

                  <SvgNotificationFilled width={25} height={25} color={unselectColor} />
                </Col> */}
                <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                    <div style={{ fontSize: 18, fontWeight: '600', marginRight: 10, textAlign: 'center' }}>
                      {userInfo.fullName}
                    </div>
                    <Popover
                      placement="bottomRight"
                      title={'Profile'}
                      overlayStyle={{ width: 300 }}
                      content={
                        <>
                          <Button
                            type="primary"
                            size="large"
                            style={{ borderRadius: 10, width: '100%' }}
                            onClick={() => handleLogout()}
                          >
                            Logout
                          </Button>
                        </>
                      }
                    >
                      {userInfo.profileUrl ? (
                        <Image
                          preview={false}
                          style={{
                            width: 45,
                            height: 45,
                            padding: 1,
                            objectFit: 'cover',
                            cursor: 'pointer',
                            borderRadius: 30,
                            border: '2px solid',
                          }}
                          onError={(e, m) => {
                            fetchUserInfo();
                          }}
                          src={userInfo.profileUrl}
                        />
                      ) : (
                        <Avatar shape="circle" size={45} icon={<UserOutlined />} />
                      )}
                    </Popover>
                  </div>
                </Col>
              </Row>
            </Header>
            <Layout>
              <Sider className="custom-side-bar" width={250}>
                <ConfigProvider
                  theme={{
                    token: {
                      controlHeightLG: 50,
                      margin: 0,
                    },
                    components: {
                      Menu: {
                        itemMarginInline: 0,
                        itemMarginBlock: 0,
                      },
                    },
                  }}
                >
                  <Menu
                    style={{
                      background: Theme.colors.light_gray_2,
                      border: 'none',
                    }}
                    // onSelect={handleSelectMenu}
                    // selectedKeys={[currentSelectedMenuIndex]}
                    mode="inline"
                  >
                    {SIDE_MENU.map((menu, index) => {
                      const currentIndex = '' + (index + 1);
                      if (index === 0) {
                        return (
                          <>
                            <div className="side-bar-header">{menu.header}</div>
                            <MenuItem
                              key={currentIndex}
                              icon={GenerateSvgIconBaseOnName(menu.name, selected, currentIndex)}
                            >
                              <Link to={menu.route} onClick={() => handleMenuClick(menu.route)}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <span className="sidebar-text">{menu.name}</span>
                                </div>
                              </Link>
                            </MenuItem>
                          </>
                        );
                      } else {
                        const previewMenu = SIDE_MENU[index - 1];
                        if (previewMenu.header === menu.header) {
                          return (
                            <>
                              <MenuItem
                                key={currentIndex}
                                icon={GenerateSvgIconBaseOnName(menu.name, selected, currentIndex)}
                              >
                                <Link to={menu.route} onClick={() => handleMenuClick(menu.route)}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className="sidebar-text">{menu.name}</span>
                                  </div>
                                </Link>
                              </MenuItem>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <div className="side-bar-header">{menu.header}</div>
                              <MenuItem
                                key={currentIndex}
                                icon={GenerateSvgIconBaseOnName(menu.name, selected, currentIndex)}
                              >
                                <Link to={menu.route} onClick={() => handleMenuClick(menu.route)}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className="sidebar-text">{menu.name}</span>
                                  </div>
                                </Link>
                              </MenuItem>
                            </>
                          );
                        }
                        // }
                      }
                    })}
                  </Menu>
                </ConfigProvider>
              </Sider>
              <Layout>
                <Content className="custom-content">
                  <Outlet />
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </div>
      </ConfigProvider>
    </>
  );
}
export default App;
