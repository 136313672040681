import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Skeleton, Space, Tabs } from 'antd';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import { getRowClassName, NJVAddButton, NJVEditButton, NJVTable, TitleLevel3 } from '../../component/core-component';
import { Constant, FaqType } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const FaqPage = () => {
  const pageSize = Constant.pageSize;
  const type = '';
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);
  const [selectedId, setSelectedId] = useState();
  const [isModelOpen, toggleModelOpen] = useToggle(false);
  const [selectedType, setSelectedType] = useState('Passenger');

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      UserType: selectedType,
    };
    ApiHandler({
      url: Api.faq_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        const content = response.content;
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(content);
      })
      .catch(() => {})
      .finally(() => toggleDataFetching(false));
  };

  const deleteRow = (id) => {
    ApiHandler({
      url: Api.delete_faq,
      method: HTTP_METHOD.POST,
      requestData: {
        id,
      },
    }).finally(() => {
      toggleModelOpen(false);
      fetchData();
    });
  };

  useEffect(() => {
    toggleDataFetching();
    fetchData();
  }, [selectedType]);

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Question (EN)',
      dataIndex: 'questionEng',
    },
    {
      title: 'Answer (EN)',
      dataIndex: 'answerEng',
    },
    {
      title: 'FAQ Type',
      dataIndex: 'faqType',
      render: (faqType) => <span>{FaqType[faqType]}</span>,
    },
    {
      title: 'Action',
      textAlign: 'center',
      width: 100,
      render: (row) => (
        <Space>
          <Link to={`${CustomPath.faq_update}/${row.id}`}>
            <NJVEditButton type={type} />
          </Link>
          <Button
            size={'middle'}
            type="primary"
            danger
            shape="circle"
            onClick={() => {
              setSelectedId(row.id);
              toggleModelOpen();
            }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };
  const onTypeChange = (key) => {
    setSelectedType(key);
  };

  const TabContent = () => (
    <>
      {isFetching ? (
        <Skeleton active />
      ) : (
        <NJVTable
          rowKey="id"
          columns={columns}
          dataSource={data}
          pagination={{
            position: 'top' | 'bottom',
            total: totalPagination,
            current: page + 1,
            onChange: handlePaginationChange,
            defaultPageSize: pageSize,
            showSizeChanger: false,
          }}
          rowClassName={getRowClassName}
        />
      )}
    </>
  );
  const tabsItem = [
    {
      key: 'Passenger',
      label: 'Passenger',
      children: <TabContent />,
    },
    {
      key: 'Driver',
      label: 'Driver',
      children: <TabContent />,
    },
  ];
  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'FAQ'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to={CustomPath.faq_form}>
            <NJVAddButton type={type} />
          </Link>
        </Col>
        <Col span={24}>
          <Tabs defaultActiveKey="1" items={tabsItem} onChange={onTypeChange} />
        </Col>
      </Row>
      <Modal
        title="Delete FAQ"
        open={isModelOpen}
        onOk={() => deleteRow(selectedId)}
        okType="danger"
        onCancel={toggleModelOpen}
      >
        <p>Are you sure want to delete?</p>
      </Modal>
    </>
  );
};

export default FaqPage;
