import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Row, Skeleton, Space, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVInput,
  NJVTable,
  TitleLevel3,
} from '../../component/core-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

function RegionPage() {
  const pageSize = Constant.pageSize;

  const updateFormRef = useRef();

  const [page, setPage] = useState(1);
  const [totalPagination, setPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);
  const [updateDataTemp, setUpdateDataTemp] = useState(null);

  const [openCreateFormDrawer, setOpenCreateFormDrawer] = useState(false);
  const [openUpdateFormDrawer, setOpenUpdateFormDrawer] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setDataFetching(true);
    await ApiHandler({
      url: Api.region_filter,
      method: HTTP_METHOD.GET,
      mediaType: MEDIA_TYPE.FORM_DATA,
    })
      .then((response) => {
        setData(response?.content);
      })
      .catch((error) => {})
      .finally(() => setDataFetching(false));
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Region Name',
      key: 'regionName',
      dataIndex: 'regionName',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      textAlign: 'center',
      width: 60,
      render: (region) => (
        <Space>
          <Button
            size={'middle'}
            type="primary"
            shape="circle"
            onClick={() => {
              setUpdateDataTemp(region);
              console.log(updateDataTemp);
              setOpenUpdateFormDrawer(true);
            }}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = () => {};

  const closeCreateFormDrawer = () => {
    setOpenCreateFormDrawer(false);
  };
  const closeUpdateFormDrawer = () => {
    setOpenUpdateFormDrawer(false);
  };

  const createRegion = async (formData) => {
    console.log(formData);
    setCreateLoading(true);
    const requestData = {
      regionName: formData.regionName,
    };
    await ApiHandler({
      url: Api.region,
      method: HTTP_METHOD.POST,
      mediaType: MEDIA_TYPE.JSON,
      requestData,
    })
      .then((response) => {
        setCreateLoading(false);
        closeCreateFormDrawer();
        fetchData();
      })
      .catch((error) => {
        setCreateLoading(false);
      });
  };
  const updateRegion = async (formData) => {
    setUpdateLoading(true);
    const requestData = {
      id: updateDataTemp.id,
      regionName: formData.regionName,
    };
    await ApiHandler({
      url: Api.region,
      method: HTTP_METHOD.PUT,
      mediaType: MEDIA_TYPE.JSON,
      requestData,
    })
      .then((response) => {
        setUpdateLoading(false);
        closeUpdateFormDrawer();
        setUpdateDataTemp(null);
        fetchData();
      })
      .catch((error) => {
        setUpdateLoading(false);
      });
  };

  const CreateFormDrawer = () => (
    <Drawer
      title="Create a new region"
      width={500}
      onClose={closeCreateFormDrawer}
      open={openCreateFormDrawer}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <Form layout="vertical" onFinish={createRegion}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="regionName" label={'Region Name'} rules={[{ required: true, message: '*(requried)' }]}>
              <NJVInput bgcolor={'#f1f1f1'} placeholder={'Enter region name'} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <NJVButton type="primary" htmlType="submit" loading={createLoading}>
                Create
              </NJVButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );

  const UpdateFormDrawer = () => (
    <Drawer
      title="Update region"
      width={500}
      onClose={closeUpdateFormDrawer}
      open={openUpdateFormDrawer}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <Form layout="vertical" onFinish={updateRegion} initialValues={{ regionName: updateDataTemp?.regionName }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="regionName" label={'Region Name'} rules={[{ required: true, message: '*(requried)' }]}>
              <NJVInput value={updateDataTemp?.regionName} bgcolor={'#f1f1f1'} placeholder={'Enter region name'} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <NJVButton type="primary" htmlType="submit" loading={updateLoading}>
                Update
              </NJVButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );

  return (
    <>
      <CreateFormDrawer />
      <UpdateFormDrawer />
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Region'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <NJVAddButton handleClick={() => setOpenCreateFormDrawer(true)} />
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
export default RegionPage;
