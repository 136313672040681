import { EditOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Skeleton, Space, Table, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Constant, UserStatus } from '../../core/constant';
import {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVInput,
  NJVTable,
  TitleLevel3,
} from '../../component/core-component';
import CustomPath from '../../route/custom-path';
import Api from '../../network/api';
import { ApiHandler } from '../../network/network-manager';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';

function BookingPage() {
  const pageSize = Constant.pageSize;

  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState();

  const handleSearch = () => {
    fetchData({});
  };

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      name: filterData?.searchFullName?.trim() ? filterData?.searchFullName?.trim() : '',
      phoneNumber: filterData?.searchPhoneNumber?.trim() ? filterData?.searchPhoneNumber?.trim() : '',
    };

    ApiHandler({
      url: Api.booking,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => setDataFetching(false));
  };

  useEffect(() => {
    setDataFetching(true);
    fetchData();
  }, []);

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Passenger Name',
      key: 'fullName',
      dataIndex: 'fullName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Pickup Address',
      dataIndex: 'pickupAddress',
      key: 'pickupAddress',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      textAlign: 'center',
      width: 60,
      render: (passenger) => (
        <Button size={'middle'} type="primary" shape="circle">
          Assign
        </Button>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Booking List'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}></Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by Passenger Name"
            onChange={(e) => onChangeFilter('searchFullName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by PhoneNumber"
            onChange={(e) => onChangeFilter('searchPhoneNumber', e.target.value)}
          />
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey={'id'}
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
export default BookingPage;
