import { Breadcrumb, Button, Card, Checkbox, Col, Form, Image, Row, Select, Upload } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NJVButton, NJVDatePicker, NJVInput, NJVSelect } from '../../../component/core-component';
import Theme from '../../../component/theme';
import CustomPath from '../../../route/custom-path';
import { ApiHandler } from '../../../network/network-manager';
import Api from '../../../network/api';
import { HTTP_METHOD } from '../../../network/httpMethod';
import { MEDIA_TYPE } from '../../../network/mediaType';
import { Gender } from '../../../core/constant';
import { getBase64 } from '../../../core/utils';
import ProfileUploadButton from '../../../component/profile-upload-button';
import { PhoneNumberValidator } from '../../../core/validator';

function PassengerForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadedFile, setUploadedFile] = useState();

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const createPassenger = (formData) => {
    setLoading(true);
    formData = {
      ...formData,
      dateOfBirth: formData.dateOfBirth ? formData.dateOfBirth.format('YYYY-MM-DD') : null,
      status: formData.active ? 'Active' : 'Inactive',
    };
    const requestData = new FormData();
    Object.keys(formData).forEach((key) => {
      formData[key] && requestData.append(key, formData[key]);
    });
    uploadedFile && requestData.append('file', uploadedFile);

    ApiHandler({
      url: Api.passenger,
      method: HTTP_METHOD.POST,
      mediaType: MEDIA_TYPE.FORM_DATA,
      requestData,
    })
      .then((response) => {
        backToHome();
      })
      .catch((error) => setLoading(false));
  };

  const backToHome = () => navigate(CustomPath.passenger);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.passenger}>Passenger</Link>,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'Passenger Form'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form layout="vertical" initialValues={{ active: true }} onFinish={createPassenger}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item>
                    <Upload
                      beforeUpload={(file) => {
                        setUploadedFile(file);
                        return false;
                      }}
                      onRemove={() => {
                        setUploadedFile(undefined);
                      }}
                      listType="picture-circle"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length === 1 ? null : <ProfileUploadButton />}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="fullName" label={'Name'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter name'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phoneNumber"
                    label={'Phone Number'}
                    rules={[{ required: true, message: '*(requried)' }, { validator: PhoneNumberValidator }]}
                  >
                    <NJVInput placeholder={'0979xxxxxxxx'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="emailAddress"
                    label={'Email Address'}
                    rules={[
                      {
                        type: 'email',
                        message: 'Invalid email address',
                      },
                    ]}
                  >
                    <NJVInput placeholder={'Enter email address'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="dateOfBirth" label={'Date Of Birth'}>
                    <NJVDatePicker size="large" style={{ width: '100%' }} placeholder={'Select Date Of Birth'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="gender" label={'Gender'}>
                    <NJVSelect size="large">
                      {Object.entries(Gender).map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="active" valuePropName="checked">
                    <Checkbox>Active</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <NJVButton type="primary" htmlType="submit" loading={loading}>
                  Create
                </NJVButton>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  );
}

export default PassengerForm;
